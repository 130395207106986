import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const markAsScannedOrderBag = async (params?: any) => {
  return await axiosClient.post<any>(`share/markAsScannedOrderBag`, {
    ...params,
  });
};

export const useMarkAsScannedOrderBag = (config = {}) => {
  return useMutation({ mutationFn: (params: any) => markAsScannedOrderBag(params), ...config });
};
