import { EditOutlined } from '@ant-design/icons';
import { Button, message, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableAntCustom from '~/components/antd/ITable/TableAntCustom';
import { SERVER_PREFIX } from '~/contants/commons';
import { mappingLabelOperaOrder, StatusOfOrder } from '~/contants/order';
import { useGlobalContext } from '~/contexts/GlobalContext';
import { useOrderUpdateMutation } from '~/queries/settings/useConfigSLAMutation';

export interface SLA {
  enable: boolean;
  orderStatus: StatusOfOrder;
  overdueMinutes: number;
}

const columns = ({ onEdit, onToggleStatus }: any) => [
  {
    title: 'Tên SLA',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    render: (orderStatus: StatusOfOrder) => {
      return <>{mappingLabelOperaOrder?.[orderStatus]?.label}</>;
    },
  },
  {
    title: 'Thời hạn (Phút)',
    dataIndex: 'overdueMinutes',
    className: 'text-center',
    key: 'overdueMinutes',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'enable',
    className: 'text-center',
    key: 'enable',
    render: (enable: boolean, record: SLA) => {
      return <Switch onChange={() => onToggleStatus(record)} checked={enable} defaultChecked />;
    },
  },
  {
    title: 'Thời gian áp dụng',
    dataIndex: 'applyTimes',
    className: 'text-center',
    key: 'applyTimes',
    render: (applyTimes: any) => {
      return <span>Thứ 2 - Chủ nhật ({applyTimes?.[0]?.workingHours?.[0]})</span>;
    },
  },
  {
    title: '#',
    dataIndex: 'action',
    key: 'action',
    className: 'text-center',
    render: (_: any, record: SLA) => {
      return <Button onClick={() => onEdit(record)} type="text" icon={<EditOutlined />}></Button>;
    },
  },
];

const SLASettingList = () => {
  const navigate = useNavigate();
  const { slaStatusOverdues, setConfig, allConfig, setVersion, setConfigStore } =
    useGlobalContext() as any;

  const { mutate: updateSLA, isSuccess, data } = useOrderUpdateMutation();
  const [slaSubmit, setSLASubmit] = useState<SLA>();

  useEffect(() => {
    if (data?.error) {
      message.error(SERVER_PREFIX + data?.error);
    } else if (isSuccess) {
      message.success('Cập nhật SLA thành công');
      updateJsonStore(slaSubmit);
      setVersion(JSON.stringify(data));
    }
  }, [isSuccess]);

  const updateJsonStore = (slaObj?: SLA) => {
    const allConfigCopy = { ...allConfig };
    const slaStatusOverduesCopy = [...slaStatusOverdues];
    const indexOfStatus = slaStatusOverdues?.findIndex(
      (slaStatusOverdue: SLA) => slaStatusOverdue?.orderStatus === slaObj?.orderStatus
    );
    if (indexOfStatus !== -1) {
      slaStatusOverduesCopy[indexOfStatus] = { ...slaObj };
      allConfigCopy['slaStatusOverdues'] = [...slaStatusOverduesCopy];

      setConfig({});
      setConfig({ ...allConfigCopy });
      setConfigStore(JSON.stringify({ ...allConfigCopy }));
    }
  };

  const handleToggleStatus = (slaObj: SLA) => {
    updateSLA({ ...slaObj, enable: !slaObj.enable });
    setSLASubmit({ ...slaObj, enable: !slaObj.enable });
  };

  return (
    <>
      <TableAntCustom
        iKey="key"
        loading={false}
        columns={columns({
          onToggleStatus: handleToggleStatus,
          onEdit: (SLAObj: SLA) =>
            navigate(`/system-setting/sla-setting/${SLAObj?.orderStatus}`, {
              state: { ...SLAObj },
            }),
        })}
        dataSource={slaStatusOverdues || []}
        pagination={false}
      ></TableAntCustom>
    </>
  );
};

export default SLASettingList;
