import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Tag,
  TimePicker,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SCard } from '~/components/commons';
import { SERVER_PREFIX } from '~/contants/commons';
import { mappingLabelOperaOrder } from '~/contants/order';
import { useGlobalContext } from '~/contexts/GlobalContext';
import { SLA } from '~/modules/system-setting/pages/SLASetting/SLASettingList/SLASettingList';
import { useOrderUpdateMutation } from '~/queries/settings/useConfigSLAMutation';

const { Option } = Select;

const dayOfWeeks = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

const selectAfter = (
  <Select defaultValue="minute" disabled>
    <Option value="minute">Phút</Option>
    <Option value="hour">Giờ</Option>
  </Select>
);
const SLASettingEdittor = () => {
  const { slaStatusOverdues, setConfig, allConfig, setConfigStore } = useGlobalContext() as any;
  const [slaSubmit, setSLASubmit] = useState<any>();
  const navigate = useNavigate();
  const { status } = useParams();
  const location = useLocation();

  let { enable, overdueMinutes, applyTimes } = (location.state as any) || {};

  const splitTime = applyTimes?.[0]?.workingHours?.[0]?.split('-');

  const firstTime = moment(splitTime?.[0].trim() + ':00', 'HH:mm') || moment('00:00:00', 'HH:mm');
  const lastTime = moment(splitTime?.[1].trim() + ':00', 'HH:mm') || moment('00:00:00', 'HH:mm');

  const { mutate: updateSLA, isSuccess, isLoading, data } = useOrderUpdateMutation();

  useEffect(() => {
    if (data?.error) {
      message.error(SERVER_PREFIX + data?.error);
    } else if (isSuccess) {
      message.success('Cập nhật SLA thành công');
      updateJsonStore({ ...slaSubmit });
      return navigate('/system-setting/sla-setting');
    }
  }, [isSuccess]);

  const updateJsonStore = (slaObj?: SLA) => {
    const allConfigCopy = { ...allConfig };
    const slaStatusOverduesCopy = [...slaStatusOverdues];
    const indexOfStatus = slaStatusOverdues?.findIndex(
      (slaStatusOverdue: SLA) => slaStatusOverdue?.orderStatus === slaObj?.orderStatus
    );
    if (indexOfStatus !== -1) {
      slaStatusOverduesCopy[indexOfStatus] = { ...slaObj };
      allConfigCopy['slaStatusOverdues'] = [...slaStatusOverduesCopy];
      setConfig({});
      setConfig({ ...allConfigCopy });
      setConfigStore(JSON.stringify({ ...allConfigCopy }));
    }
  };

  const [form] = Form.useForm<{
    overdueMinutes: number;
    applyTimes?: Array<any>;
    orderStatus?: any;
    enable: boolean;
  }>();

  useEffect(() => {
    if (!location.state) {
      return navigate('/system-setting/sla-setting');
    }
    form.setFieldsValue({
      overdueMinutes,
      applyTimes: [firstTime, lastTime],
      enable,
    });
  }, [location.state]);

  const handleUpdateSLA = (values: any) => {
    const firstTime1 = moment(values?.applyTimes?.[0]).format('HH:mm');
    const lastTime1 = moment(values?.applyTimes?.[1]).format('HH:mm');
    const newApplyTime = dayOfWeeks.map((dayOfWeek) => ({
      dayOfWeek: dayOfWeek,
      workingHours: [String(firstTime1) + '-' + String(lastTime1)],
    }));
    updateSLA({
      ...values,
      orderStatus: status,
      applyTimes: newApplyTime,
    });
    setSLASubmit({
      ...values,
      orderStatus: status,
      applyTimes: newApplyTime,
    });
  };

  return (
    <>
      <div className="flex justify-center">
        <SCard rounded style={{ maxWidth: '1000px', minWidth: '700px' }}>
          <h3 className="mb-0 flex justify-between ">
            <span>Thông tin SLA </span>
            <div>
              <Tag color={mappingLabelOperaOrder?.[status as any]?.color}>
                {mappingLabelOperaOrder?.[status as any]?.label}
              </Tag>
            </div>
          </h3>
          <Divider style={{ margin: '10px 0px' }} />
          <Form
            initialValues={{ remember: true }}
            onFinish={(values) => {
              handleUpdateSLA(values);
            }}
            onFinishFailed={() => {}}
            autoComplete="off"
            className="p-3"
            layout="vertical"
            form={form}
          >
            <div className="flex flex-col">
              <Row gutter={10}>
                <Col span={17}>
                  <div className="flex-flex-col mt-2">
                    <label className="font-medium">Thời gian áp dụng</label>
                    <br />
                    <Select disabled value="2-cn" style={{ width: '100%' }}>
                      <Select.Option value="2-cn">Thứ 2 - Chủ nhật</Select.Option>
                    </Select>
                  </div>
                </Col>
                <Col span={7}>
                  <div className="flex-flex-col mt-2">
                    <label className="font-medium"></label>
                    <br />
                    <Form.Item
                      name="applyTimes"
                      rules={[{ required: true, message: 'Trường này bắt buộc!' }]}
                    >
                      <TimePicker.RangePicker
                        className="w-100"
                        format={'HH:mm'}
                        defaultValue={[firstTime, lastTime]}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <div className="flex flex-col mt-5">
                <label className="font-medium">Trạng thái: </label>
                <Form.Item
                  name="enable"
                  rules={[{ required: true, message: 'Trường này bắt buộc!' }]}
                >
                  <Select className="w-100">
                    <Select.Option value={true}>Đang kích hoạt</Select.Option>
                    <Select.Option value={false}>Ngừng kích hoạt</Select.Option>
                  </Select>
                </Form.Item>
              </div>

              <div className="flex flex-col mt-2">
                <label className="font-medium">
                  Thời hạn: <small>(Bội số của 5)</small>{' '}
                </label>
                <Form.Item
                  name="overdueMinutes"
                  rules={[{ required: true, message: 'Trường này bắt buộc!' }]}
                >
                  <Input placeholder="Nhập thời hạn" addonAfter={selectAfter} />
                </Form.Item>
              </div>
              <Form.Item className="mt-5 ml-auto">
                <Space>
                  <Button onClick={() => navigate('/system-setting/sla-setting')}>Huỷ</Button>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    Lưu
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Form>
        </SCard>
      </div>
    </>
  );
};

export default SLASettingEdittor;
