export const dataCharts = ({ title, data = [] }: any) => ({
  chart: {
    type: 'bar',
    height: window.innerHeight - 200,
    dataLabels: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  title: {
    text: title,
    align: 'center',
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: undefined,
      color: '#263238',
    },
  },
  series: [
    {
      name: '',
      data,
    },
  ],

  tooltip: {
    x: {
      show: false,
      format: 'dd MMM',
      formatter: undefined,
    },
    y: {
      formatter: undefined,
      // title: {
      //   formatter: (seriesName) => seriesName,
      // },
    },
    marker: {
      show: false,
    },
  },
  yaxis: {
    axisBorder: {
      show: true,
    },
    labels: {
      show: true,
      formatter: function (val) {
        return val;
      },
    },
  },
  grid: {
    show: true,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '85%',
    },
  },
});
