import { useParams } from 'react-router-dom';
import { useOrderShareDetailQuery } from '~/queries/orders/useOrderDetailQuery';
import View from '~/modules/export-document/invoice/View';
import { useEffect, useState } from 'react';
import { Alert, message } from 'antd';
const Invoice = () => {
  const { code } = useParams();
  const [isReadyScreenshot, setIsReadyScreenshot] = useState(false);
  const { data: orderDetail, isLoading = false } = useOrderShareDetailQuery(code);

  useEffect(() => {
    let timeout;
    if (orderDetail) {
      timeout = setTimeout(() => {
        setIsReadyScreenshot(true);
      }, 200);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [orderDetail]);

  useEffect(() => {
    if (orderDetail?.error) {
      message.error(orderDetail?.error);
    }
  }, [orderDetail?.error]);

  if (orderDetail?.error)
    return (
      <div className="p-3 flex items-center justify-center">
        <Alert type="error" description={orderDetail?.error} />
      </div>
    );

  if (isLoading) return <></>;

  return <View orderDetail={orderDetail} isReadyScreenshot={isReadyScreenshot} />;
};

export default Invoice;
