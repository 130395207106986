import React, { Fragment } from 'react';
import AuthLayout from '~/components/layout/AuthLayout/AuthLayout';
import DefaultLayout from '~/components/layout/DefaultLayout/DefaultLayout';
import notfound from '~/components/notfound';
import Authorize from '~/modules/auth/pages/authorize';
import Login from '~/modules/auth/pages/login/Login';
import Bill from '~/modules/bill';
import ListBill from '~/modules/bill/pages/list-bill/ListBill';
import ListSorryVoucher from '~/modules/bill/pages/sorry-voucher/ListSorryVoucher';
import Customer from '~/modules/customer';
import Customers from '~/modules/customer/pages/list-customer/CustomerWrapper';
import ExportDocument from '~/modules/export-document';
import invoice from '~/modules/export-document/invoice';
import KDB from '~/modules/kdb';
import DeiveryOrderBags from '~/modules/kdb/deivery-order-bags';
import HomeDelivery from '~/modules/kdb/home-delivery';
import LabelOrderBags from '~/modules/kdb/label-order-bags';
import OrderBags from '~/modules/kdb/update-order-bags';
import OperateOrder from '~/modules/operate-order';
import CouponDetail from '~/modules/operate-order/pages/CouponDetail';
import ProductOperation from '~/modules/product-operation';
import Stock from '~/modules/statistical/pages/stock/Stock';
import SystemSetting from '~/modules/system-setting';
import EmployeeSetting from '~/modules/system-setting/pages/EmployeeSetting';
import WareHouseProductWrapper from '~/modules/system-setting/pages/InventoryProducts/InventoryProductsWrapper';
import WarehouseProductStockAvailableCreate from '~/modules/system-setting/pages/InventoryProductStockAvailableCreate/InventoryProductStockAvailableCreate';
import WarehouseProductStockAvailableEdit from '~/modules/system-setting/pages/InventoryProductStockAvailableEdit';
import SLASetting from '~/modules/system-setting/pages/SLASetting';
import SLASettingEdittor from '~/modules/system-setting/pages/SLASettingEdittor/SLASettingEdittor';

import TrackingStocks from '~/modules/tracking/pages/stocks';

const Home = React.lazy(() => import('~/modules/home/Home'));
const OrderRating = React.lazy(() => import('~/modules/operate-order/pages/order-rating'));

// Order router
const OrderListNew = React.lazy(() => import('~/modules/operate-order/pages/OrderList'));
const OrderDetail = React.lazy(() => import('~/modules/operate-order/pages/OrderDetail'));

const Tracking = React.lazy(() => import('~/modules/tracking'));

const CallHistory = React.lazy(
  () => import('~/modules/call-center/pages/call-history/CallHistory')
);

const CallTicket = React.lazy(() => import('~/modules/call-center/pages/call-ticket'));

const CustomerDetail = React.lazy(
  () => import('~/modules/customer/pages/customer-detail/CustomerDetail/CustomerDetail')
);

const ListProductWrapper = React.lazy(
  () => import('~/modules/product-operation/pages/list-product/Products')
);

const StoreManage = React.lazy(() => import('~/modules/system-setting/pages/StoreManage'));
const InternalShipping = React.lazy(() => import('~/modules/system-setting/pages/InternalShipping'));

//statistical

const Statistical = React.lazy(() => import('~/modules/statistical'));
const CSTask = React.lazy(() => import('~/modules/statistical/pages/CSTask'));

// Transporting router

export enum ScopeRoute {
  'PRIVATE' = 'PRIVATE',
  'PUBLIC' = 'PUBLIC',
}

export interface RouterDefine {
  path: string;
  element: React.FC<any>;
  layout?: React.FC<{ children: React.ReactNode }>;
  scope: ScopeRoute;
  children?: RouterDefine[];
  permisions?: boolean;
  title?: string;
}

const routers: RouterDefine[] = [
  {
    path: '/',
    element: Home,
    permisions: true,
    layout: DefaultLayout,
    scope: ScopeRoute.PRIVATE,
    title: 'Home',
  },
  {
    path: 'order',
    element: OperateOrder,
    permisions: true,
    scope: ScopeRoute.PRIVATE,
    children: [
      {
        path: 'list-order',
        element: OrderListNew,
        permisions: true,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Đơn hàng',
      },
      {
        path: 'order-detail/:code',
        permisions: true,
        element: OrderDetail,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Chi tiết đơn hàng',
      },
      {
        path: 'order-detail/edit-delivery/:code',
        permisions: true,
        element: CouponDetail,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Chỉnh sửa sản phẩm',
      },
      {
        path: 'order-rating',
        element: OrderRating,
        permisions: true,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Đánh giá đơn hàng',
      },
    ],
  },
  {
    path: 'customer',
    element: Customer,
    permisions: true,
    scope: ScopeRoute.PRIVATE,
    children: [
      {
        path: 'list-customer',
        element: Customers,
        permisions: true,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Danh sách khách hàng',
      },
      {
        path: 'customer-detail/:phone',
        element: CustomerDetail,
        permisions: true,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Chi tiết khách hàng',
      },
      {
        path: 'call-history',
        element: CallHistory,
        permisions: true,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Lịch sử cuộc gọi',
      },
      {
        path: 'call-ticket',
        element: CallTicket,
        permisions: true,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Ticket cuộc gọi',
      },
    ],
  },
  {
    path: 'bill',
    element: Bill,
    permisions: true,
    scope: ScopeRoute.PRIVATE,
    children: [
      {
        path: 'list-bill',
        element: ListBill,
        permisions: true,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Phiếu hoàn tiền',
      },
      {
        path: 'list-sorry-voucher',
        element: ListSorryVoucher,
        permisions: true,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Danh sách Sorry Voucher',
      },
    ],
  },
  {
    path: 'product-operation',
    element: ProductOperation,
    permisions: true,
    scope: ScopeRoute.PRIVATE,
    children: [
      {
        path: 'products',
        element: ListProductWrapper,
        permisions: true,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Danh sách sản phẩm',
      },
    ],
  },
  {
    path: 'system-setting',
    element: SystemSetting,
    permisions: true,
    scope: ScopeRoute.PRIVATE,
    children: [
      {
        path: 'warehouse-products',
        permisions: true,
        element: WareHouseProductWrapper,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Cấu hình stock pre-order',
      },
      {
        path: 'warehouse-products/stock-available/:barcode',
        permisions: true,
        element: WarehouseProductStockAvailableEdit,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Chỉnh sửa Stock PreOrder',
      },
      {
        path: 'warehouse-products/create-stock-available',
        permisions: true,
        element: WarehouseProductStockAvailableCreate,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Tạo Stock PreOrder',
      },

      {
        path: 'warehouse-products',
        permisions: true,
        element: WareHouseProductWrapper,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
      },
      {
        path: 'sla-setting',
        permisions: true,
        element: SLASetting,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Cấu hình SLA',
      },
      {
        path: 'sla-setting/:status',
        permisions: true,
        element: SLASettingEdittor,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Cấu hình SLA',
      },
      {
        path: 'employee-setting',
        permisions: true,
        element: EmployeeSetting,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Quản lý nhân viên',
      },
      {
        path: 'store-manage',
        permisions: true,
        element: StoreManage,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Quản lý cửa hàng',
      },
      {
        path: 'internal-shipper',
        permisions: true,
        element: InternalShipping,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Tài xế nội bộ',
      },
    ],
  },
  {
    path: 'statistical',
    element: Statistical,
    permisions: true,
    scope: ScopeRoute.PRIVATE,
    children: [
      {
        path: 'stock',
        permisions: true,
        element: Stock,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Stock',
      },
      {
        path: 'cs-task',
        permisions: true,
        element: CSTask,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'CS Task',
      },
    ],
  },
  {
    path: '/tracking',
    element: Tracking,
    permisions: true,
    scope: ScopeRoute.PRIVATE,
    children: [
      {
        path: 'stocks',
        element: TrackingStocks,
        permisions: true,
        layout: DefaultLayout,
        scope: ScopeRoute.PRIVATE,
        title: 'Tracking Stock',
      },
    ],
  },
  {
    path: '/login',
    element: Login,
    layout: AuthLayout,
    scope: ScopeRoute.PUBLIC,
  },
  {
    path: '/authorize',
    element: Authorize,
    layout: AuthLayout,
    scope: ScopeRoute.PUBLIC,
  },
  {
    path: 'export',
    element: ExportDocument,
    layout: Fragment,
    scope: ScopeRoute.PUBLIC,
    children: [
      {
        path: 'invoice/:code',
        element: invoice,
        permisions: true,
        scope: ScopeRoute.PUBLIC,
      },
    ],
  },
  {
    path: 'home-delivery',
    element: KDB,
    permisions: true,
    scope: ScopeRoute.PRIVATE,
    children: [
      {
        path: 'order-detail/:orderCode',
        permisions: true,
        element: HomeDelivery,
        layout: React.Fragment,
        scope: ScopeRoute.PRIVATE,
      },
    ],
  },
  {
    path: 'kdb',
    element: KDB,
    permisions: true,
    scope: ScopeRoute.PUBLIC,
    children: [
      {
        path: 'update-order-bags',
        permisions: true,
        element: OrderBags,
        layout: React.Fragment,
        scope: ScopeRoute.PUBLIC,
      },
      {
        path: 'delivery-order-bags',
        permisions: true,
        element: DeiveryOrderBags,
        layout: React.Fragment,
        scope: ScopeRoute.PUBLIC,
      },
      {
        path: 'label-order-bags',
        permisions: true,
        element: LabelOrderBags,
        layout: React.Fragment,
        scope: ScopeRoute.PUBLIC,
      },
    ],
  },
  {
    path: '*',
    element: notfound,
    layout: DefaultLayout,
    scope: ScopeRoute.PUBLIC,
  },
];

export default routers;
