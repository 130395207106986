import { Button, InputNumber } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const InputAddCart = ({
  onMinus,
  onPlus,
  amount,
  onChangeAmount,
}: {
  onMinus?: () => void;
  onPlus?: () => void;
  amount?: number;
  onChangeAmount?: (amount: number) => void;
}) => (
  <div className="w-7/12 flex justify-between items-center ">
    <Button
      size="small"
      onClick={onMinus}
      className="rounded-l-xl px-1"
      icon={<MinusOutlined style={{ color: 'var(--ant-primary-color)' }} />}
    ></Button>
    <InputNumber
      size="small"
      value={amount}
      onChange={(value: any) => onChangeAmount?.(Number(value))}
      controls={false}
      className="rounded-none text-xs"
      style={{ borderRight: 0, borderLeft: 0, minWidth: '20px' }}
      min={0}
    />
    <Button
      size="small"
      onClick={onPlus}
      className="rounded-r-xl px-1"
      icon={<PlusOutlined style={{ color: 'var(--ant-primary-color)' }} />}
    ></Button>
  </div>
);

export default InputAddCart;
