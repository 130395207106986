import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';
import { PackageSize } from '~/contants/order';

interface StoreBookShipperRequest {
  orderCode: string;
  packageSize: PackageSize;
}

const storeBookShipper = async (params: StoreBookShipperRequest) => {
  return await axiosClient.post<any>(`share/storeBookShipper`, {
    ...params,
  });
};

export const useStoreBookShipper = () => {
  return useMutation({ mutationFn: (params: StoreBookShipperRequest) => storeBookShipper(params) });
};
