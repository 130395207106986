import React, {
  ChangeEvent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import Loading from '~/components/loading';
import { useOrderDetailQuery } from '~/queries/orders/useOrderDetailQuery';
import { OrderDelivery, OrderDetail } from '~/types/orderDetailType';

type CouponDetailContextType = {
  orderDetail: OrderDetail;
  delivery?: OrderDelivery;
  onChangeNote?: (note: ChangeEvent) => void;
};

const CouponDetailContext = createContext<CouponDetailContextType>({
  orderDetail: {},
});

type Props = {
  children: React.ReactNode;
};

const CouponDetailProvider: React.FC<Props> = ({ children }) => {
  const { code } = useParams();

  const { data: orderDetail, isLoading } = useOrderDetailQuery(code);

  const [orderDetailTransform, setOrderDetailTransform] = useState<OrderDetail>({});

  const delivery = orderDetailTransform?.delivery;

  useEffect(() => {
    if (orderDetail && Object.values(orderDetail)?.length) {
      setOrderDetailTransform(orderDetail);
    }
  }, [orderDetail]);

  const handleChangeNote = useCallback((event: any) => {
    const { value: note } = event.target;
    setOrderDetailTransform((currentValue) => ({
      ...currentValue,
      note,
    }));
  }, []);

  const valueContext: CouponDetailContextType = useMemo(
    () => ({
      orderDetail: orderDetailTransform,
      delivery,
      onChangeNote: handleChangeNote,
    }),
    [
      orderDetailTransform,
      delivery,
      handleChangeNote,
    ]
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CouponDetailContext.Provider value={valueContext}>{children}</CouponDetailContext.Provider>
  );
};

export default CouponDetailProvider;

export const useCouponDetailContext = () => useContext(CouponDetailContext);
