import { useQuery } from 'react-query';
import axiosClient from '~/config/axiosClient';
import { transformParams } from '../orders/useOrdersQuery';

const searchStockLogs = async (filter) => {
  return await axiosClient.get('tracking/searchStockLogs', {
    params: { filter: JSON.stringify(transformParams(filter)) },
  });
};

export const useSearchStockLogs = (
  filter,
  config = { enabled: false, cacheTime: 0, staleTime: 0 }
) => {
  return useQuery(['searchStockLogs'], () => searchStockLogs(filter), {
    ...config,
    refetchOnWindowFocus: false,
  });
};
