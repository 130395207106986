import React, { useContext, useState } from 'react';

type Props = {
  children: React.ReactNode;
};

type RouterContextType = {
  isPrivateRouter?: boolean;
  setIsPrivateRouter?: any;
};

export const RouterContext = React.createContext<RouterContextType>({
  isPrivateRouter: false,
});

const RouterContextProvider: React.FC<Props> = ({ children }): React.ReactElement => {
  const [isPrivateRouter, setIsPrivateRouter] = useState(false);

  const valueContext: RouterContextType = {
    isPrivateRouter,
    setIsPrivateRouter,
  };

  return <RouterContext.Provider value={valueContext}>{children}</RouterContext.Provider>;
};

export default RouterContextProvider;

export const useRouterContext = () => useContext(RouterContext);
