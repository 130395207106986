import WareHouseProductList from './components/WareHouseProductList/InventoryProductList';

const InventoryProductsWrapper = () => {
  return (
    <>
      <WareHouseProductList />
    </>
  );
};

export default InventoryProductsWrapper;
