import { useSidebarContext } from '~/contexts/NavbarContext';
const useSidebar = () => {
  const { isOpenSidebar, setIsOpenSidebar } = useSidebarContext();

  return {
    toggle: setIsOpenSidebar,
    isOpenSidebar,
  };
};

export default useSidebar;
