import { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AuthContext } from '~/contexts/AuthContext';
import { useRouterContext } from '~/contexts/RouterContext';

const PrivateRoute: React.FC<any> = ({ children }) => {
  const { isAuth } = useContext(AuthContext);
  const location = useLocation();

  const { setIsPrivateRouter } = useRouterContext();

  useEffect(() => {
    setIsPrivateRouter(true);
  }, [location.pathname]);

  return isAuth ? children : <Navigate to="/login" state={{ from: location }} replace />;
};

export default PrivateRoute;
