import { useLocalStorage } from 'react-use';

export const useSetRedirectAfterLogin = () => {
  const [pathname, setRedirectAfterLogin] = useLocalStorage('pathname', null);

  return {
    pathname,
    setRedirectAfterLogin,
  };
};
