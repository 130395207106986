declare interface Window {
  onWebviewReady(): void;
  SeedcomBrowser: {
    handleSDK(type: string, options?: any): Promise<any>;
  };
}
declare let window: Window;

interface IConfigOptions {
  title?: string;
  barStyle?: 'dark-content' | 'light-content';
  hideNavBar?: boolean;
}

export interface IDeviceInfo {
  device_id: string;
  device_name: string;
  device_platform: 'ios' | 'android';
  status_bar_height: number;
  bottom_padding: number;
}

const SeedcomBrowser = {
  config: (options: IConfigOptions) => {
    if (window.SeedcomBrowser) {
      window.SeedcomBrowser.handleSDK('config', options);
    }
  },
  setItem: (key: string, value: string): Promise<boolean> => {
    if (window.SeedcomBrowser) {
      return window.SeedcomBrowser.handleSDK('config', {
        key,
        value,
      });
    }
    throw new Error('Remitano Browser Undefined');
  },
  getItem: (key: string): Promise<any> => {
    if (window.SeedcomBrowser) {
      return window.SeedcomBrowser.handleSDK('config', {
        key,
      });
    }
    throw new Error('Remitano Browser Undefined');
  },
  getDeviceInfo: (): Promise<IDeviceInfo> => {
    if (window.SeedcomBrowser) {
      return window.SeedcomBrowser.handleSDK('getDeviceInfo');
    }
    throw new Error('Remitano Browser Undefined');
  },
  loginApp: (data): Promise<object> => {
    if (window.SeedcomBrowser) {
      return window.SeedcomBrowser.handleSDK('login', { data });
    }
    throw new Error('Remitano Browser Undefined');
  },
  closeBrowser: () => {
    if (window.SeedcomBrowser) {
      return window.SeedcomBrowser.handleSDK('closeBrowser');
    }
  },
  openUrl: ({ url }: { url: string }): Promise<boolean> => {
    if (window.SeedcomBrowser) {
      return window.SeedcomBrowser.handleSDK('openUrl', {
        url,
      });
    }
    throw new Error('Remitano Browser Undefined');
  },
  axiosRequest: (data: any): Promise<any> => {
    if (window.SeedcomBrowser) {
      return window.SeedcomBrowser.handleSDK('axiosRequest', data);
    }
    throw new Error('Remitano Browser Undefined');
  },
};

export default SeedcomBrowser;
