import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const updateOrderAssignSetting = async (params?: any) => {
  return await axiosClient.post<any>(`employee/updateOrderAssignSetting`, { data: { ...params } });
};

export const useUpdateOrderAssignSetting = () => {
  return useMutation({ mutationFn: (barcode: any) => updateOrderAssignSetting(barcode) });
};
