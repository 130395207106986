import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const removeProductItem = async ({
  itemId,
  reason,
  orderCode,
}: {
  itemId: string;
  reason: string;
  orderCode: string;
}) => {
  return await axiosClient.post<OrderListTypes>(`/order/removeProductItem`, {
    orderCode,
    itemId,
    reason,
  });
};

export const useRemoveProductItem = () => {
  return useMutation({
    mutationFn: ({ itemId, reason, orderCode }) =>
      removeProductItem({
        itemId,
        reason,
        orderCode,
      }),
  });
};
