import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const completeOrders = async (params?: any) => {
  return await axiosClient.post<OrderListTypes>(`order/completeOrder`, {
    ...params,
  });
};

export const useCompleteOrderMutation = () => {
  return useMutation({
    mutationFn: ({ orderCode }: { orderCode: string }) => completeOrders({ orderCode }),
  });
};
