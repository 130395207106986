import { useQuery } from 'react-query';
import axiosClient from '~/config/axiosClient';

const getDetailPreOrderStock = async (barcode: string, deliveryDate: string) => {
  const params = {
    barcode,
    deliveryDate,
  };
  return await axiosClient.get('inventory/getDetailPreOrderStock', { params });
};

export const useDetailPreOrderStockQuery = (
  barcode: string,
  deliveryDate,
  config = { enabled: true }
) => {
  return useQuery(
    ['getDetailPreOrderStock'],
    () => getDetailPreOrderStock(barcode, deliveryDate),
    {
      ...config,
    }
  );
};
