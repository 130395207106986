import { useMutation } from 'react-query';
import axiosClient from '~/config/axiosClient';

type Param = {
  token: string;
  signature: string;
};

const fetchAuthorizeIAM = async ({ token, signature }: Param) => {
  return await axiosClient.post('/auth/authorizeIAMToken', { token, signature });
};

export const useAuthorizeIAMMutation = ({ onSuccess }: { onSuccess: (data: any) => void }) => {
  return useMutation({
    mutationFn: (params: Param) => fetchAuthorizeIAM(params),
    onSuccess: (data: any) => {
      onSuccess?.(data);
    },
  });
};
