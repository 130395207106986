import CurrencyFormat from '~/components/commons/CurrencyFormat';

const OrderPayment = ({ amount, payment }) => {
  const isCOD = payment?.methodName === 'COD';
  return (
    <div className="flex flex-col gap-1">
      <span className="font-medium">
        <CurrencyFormat number={Number(amount || 0)} />
      </span>
      <div className="flex flex-col">
        <span>
          <span className={`text-sx ${!isCOD && `text-green-500`}`}>{payment?.methodName}</span>
        </span>
      </div>
    </div>
  );
};

export default OrderPayment;
