import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const deletePreOrderStock = async (barcode?: string) => {
  const params = {
    barcode,
  };
  return await axiosClient.post<any>(`inventory/deletePreOrderStock`, { ...params });
};

export const useDeletePreOrderStockMutation = () => {
  return useMutation({ mutationFn: (barcode: any) => deletePreOrderStock(barcode) });
};
