import React from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { PARAM_CREATE_ORDER } from '~/contants/commons';

const BtnCreateOrder = () => {
  const navigate = useNavigate();

  const hanldeCreateOrder = () => {
    navigate(`/order/order-detail/${PARAM_CREATE_ORDER}`);
  };

  return (
    // <Button type="primary" onClick={hanldeCreateOrder} icon={<PlusOutlined />}>
    //   Tạo đơn
    // </Button>
    <></>
  );
};

export default BtnCreateOrder;
