import { Affix, Layout } from 'antd';
import React, { memo } from 'react';
import IHeader from '../IHeader';
import Sidebar from '../Sidebar';
import { createBreakpoint } from 'react-use';

const useBreakpoint = createBreakpoint();

const { Content } = Layout;
const DefaultLayout: React.FC<any> = ({
  children,
  title,
}: {
  children?: React.ReactNode;
  title?: string;
}): React.ReactElement => {
  const breakpoint = useBreakpoint();

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Affix offsetTop={0}>
          <IHeader title={title} />
        </Affix>
        <Content
          style={{
            padding: breakpoint !== 'tablet' ? '10px 20px 20px 20px' : '10px',
            margin: 0,
            minHeight: 280,
            backgroundColor: 'var(--bg-layout)',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default memo(DefaultLayout);
