import { SCard } from '~/components/commons';
import SLASettingList from './SLASettingList';

const SLASetting = () => {
  return (
    <SCard title={'Danh sách cấu hình SLA'} bordered={false} className="mt-4 border-slate-50">
      <SLASettingList />
    </SCard>
  );
};

export default SLASetting;
