import { Button, Drawer, Form, Radio } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import { HeavyProductOptions } from '~/contants/order';

const AhamoveBookShippingConfirmation = ({ onClose, isOpen, onOk }) => {
  const [form] = Form.useForm();
  return (
    <Drawer
      placement="bottom"
      closable={false}
      onClose={onClose}
      visible={isOpen}
      height={400}
      bodyStyle={{ padding: '10px 15px' }}
      extra={<Button type="text" onClick={onClose} icon={<CloseOutlined />}></Button>}
    >
      <Form
        initialValues={{ packageSize: 'STANDARD', scheduleType: 'ORDER_DELIVERY_TIME' }}
        onFinish={onOk}
        layout="vertical"
        form={form}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="mb-0">Chọn giờ giao</h3>
          <div onClick={onClose} className="px-3 py-2 cursor-pointer">
            <CloseOutlined />
          </div>
        </div>
        <Form.Item name="scheduleType" style={{ width: '100%' }}>
          <Radio.Group defaultValue={'ORDER_DELIVERY_TIME'}>
            <div className="flex flex-col">
              <Radio className="mb-3" value={'ORDER_DELIVERY_TIME'}>
                Giao theo giờ khách chọn
              </Radio>
              <Radio className="mb-3" value={'NOW'}>
                Giao ngay
              </Radio>
            </div>
          </Radio.Group>
        </Form.Item>
        <h3>Chọn kích thước gói hàng</h3>
        <Form.Item name="packageSize" style={{ width: '100%' }}>
          <Radio.Group defaultValue={'STANDARD'}>
            {HeavyProductOptions.map((heavyProductOptions) => (
              <Radio className="mb-3" value={heavyProductOptions.value}>
                {heavyProductOptions.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Button className="float-right w-100 bg-orange-500" size="large" htmlType="submit">
          <span className="text-white font-medium">BOOK AHAMOVE</span>
        </Button>
      </Form>
    </Drawer>
  );
};

export default AhamoveBookShippingConfirmation;
