import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const resetPreOrderStock = async (barcode?: string) => {
  const params = {
    barcode,
  };
  return await axiosClient.post<any>(`inventory/resetPreOrderStock`, { ...params });
};

export const useResetPreOrderStockMutation = () => {
  return useMutation({ mutationFn: (barcode: any) => resetPreOrderStock(barcode) });
};
