export enum StatusOfOrder {
  NEW = 'NEW',
  PREORDER = 'PREORDER',
  ASSIGNED = 'ASSIGNED',
  CONFIRMED = 'CONFIRMED',
  STORE_PICKING = 'STORE_PICKING',
  STORE_PACKED = 'STORE_PACKED',
  BOOKED_SHIPPER = 'BOOKED_SHIPPER',
  SHIPPING = 'SHIPPING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
}

export enum STATUS_OF_INDEX {
  NEW = 1,
  ASSIGNED = 2,
  CONFIRMED = 3,
  STORE_PICKING = 4,
  STORE_PACKED = 5,
  BOOKED_SHIPPER = 6,
  SHIPPING = 7,
  COMPLETED = 8,
  ERROR = 1,
  CANCELLED = 1,
}

export enum StatusOfOrderText {
  NEW = 'Mới',
  PREORDER = 'Đặt trước',
  ASSIGNED = 'Đã phân công',
  CONFIRMED = 'Đã xác nhận',
  STORE_PICKING = 'Đang soạn hàng',
  STORE_PACKED = 'Soạn hàng xong',
  SHIPPING = 'Đang giao hàng',
  COMPLETED = 'Hoàn tất',
  ERROR = 'Đơn hàng lỗi',
  BOOKED_SHIPPER = 'Đã book tài xế',
  OVERDUE_SLA = 'Quá hạn SLA',
  CANCELLED = 'Đã huỷ',
  FULLFIL_ERROR = 'Lỗi fullfil',
}

export const mappingLabelOperaOrder: any = {
  [StatusOfOrder.NEW]: {
    id: 'NEW',
    key: StatusOfOrder.NEW,
    label: StatusOfOrderText.NEW,
    value: 'New',
    color: 'blue',
  },
  [StatusOfOrder.ASSIGNED]: {
    key: StatusOfOrder.ASSIGNED,
    id: 'ASSIGNED',
    label: StatusOfOrderText.ASSIGNED,
    value: 'Assigned Employee',
    color: 'cyan',
  },
  [StatusOfOrder.CONFIRMED]: {
    key: StatusOfOrder.CONFIRMED,
    id: 'CONFIRMED',
    label: StatusOfOrderText.CONFIRMED,
    value: 'Confirmed',
    color: 'orange',
  },
  [StatusOfOrder.BOOKED_SHIPPER]: {
    key: StatusOfOrder.BOOKED_SHIPPER,
    id: 'BOOKED_SHIPPER',
    label: StatusOfOrderText.BOOKED_SHIPPER,
    value: 'booked_shipper',
    color: 'gray',
  },
  [StatusOfOrder.STORE_PICKING]: {
    key: StatusOfOrder.STORE_PICKING,
    id: 'STORE_PICKING',
    label: StatusOfOrderText.STORE_PICKING,
    value: 'Store Picking',
    color: 'purple',
  },
  [StatusOfOrder.STORE_PACKED]: {
    key: StatusOfOrder.STORE_PACKED,
    id: 'STORE_PACKED',
    label: StatusOfOrderText.STORE_PACKED,
    value: 'Store Packed',
    color: 'geekblue',
  },
  [StatusOfOrder.SHIPPING]: {
    key: StatusOfOrder.SHIPPING,
    id: 'SHIPPING',
    label: StatusOfOrderText.SHIPPING,
    value: 'Shipping',
    color: 'purple',
  },
  [StatusOfOrder.COMPLETED]: {
    key: StatusOfOrder.COMPLETED,
    id: 'COMPLETED',
    label: StatusOfOrderText.COMPLETED,
    value: 'Completed',
    color: 'green',
  },
  [StatusOfOrder.ERROR]: {
    key: StatusOfOrder.ERROR,
    id: 'ERROR',
    label: StatusOfOrderText.ERROR,
    value: 'Error',
    color: 'magenta',
  },
  [StatusOfOrder.CANCELLED]: {
    key: StatusOfOrder.CANCELLED,
    id: 'CANCELLED',
    label: StatusOfOrderText.CANCELLED,
    value: 'Canceled',
    color: 'volcano',
  },
};

export type ShippingMethod = 'PROVIDER_SHIPPING' | 'CUSTOMER_PICKUP' | 'STORE_SHIPPING';

export enum ErrorOfOrder {
  ERROR_STORE_OUT_OF_STOCK = 'ERROR_STORE_OUT_OF_STOCK', // kho hết hàng
  ERROR_SHIPPING_NOT_FOUND_DRIVER = 'ERROR_SHIPPING_NOT_FOUND_DRIVER', // không book được tài xế
  ERROR_SHIPPING_NOT_DELIVERY_CUSTOMER = 'ERROR_SHIPPING_NOT_DELIVERY_CUSTOMER', // giao hàng không thành công
  ERROR_STORE_PICKED_INCORRECT_ORDER = 'ERROR_STORE_PICKED_INCORRECT_ORDER',
  ERROR_OTHER = 'ERROR_OTHER', // lý do khác
}

export enum ErrorOfOrderLabel {
  ERROR_STORE_OUT_OF_STOCK = 'Kho hết hàng', // kho hết hàng
  ERROR_SHIPPING_NOT_FOUND_DRIVER = 'Không book được tài xế', // không book được tài xế
  ERROR_SHIPPING_NOT_DELIVERY_CUSTOMER = 'Giao hàng không thành công', // giao hàng không thành công
  ERROR_STORE_PICKED_INCORRECT_ORDER = 'Lỗi cửa hàng chọn đơn hàng không đúng',
  ERROR_OTHER = 'Lý do khác', // lý do khác
}

export const SLAOrderOptions = [
  {
    value: '-1',
    label: 'Tất cả',
  },
  {
    value: StatusOfOrder.NEW,
    label: 'Quá hạn phân công',
  },
  {
    value: StatusOfOrder.ASSIGNED,
    label: 'Quá hạn xác nhận đơn',
  },
  {
    value: StatusOfOrder.CONFIRMED,
    label: 'Quá hạn kho xác nhận đơn',
  },
  {
    value: StatusOfOrder.STORE_PICKING,
    label: 'Quá hạn soạn hàng',
  },
  {
    value: StatusOfOrder.STORE_PACKED,
    label: 'Quá thời gian chờ giao hàng',
  },
  {
    value: StatusOfOrder.BOOKED_SHIPPER,
    label: 'Quá hạn vận chuyển',
  },
];

export const overDueByStatus: any = {
  [StatusOfOrder.NEW]: 'Quá hạn phân công',
  [StatusOfOrder.ASSIGNED]: 'Quá hạn cs xác nhận đơn',
  [StatusOfOrder.CONFIRMED]: 'Quá hạn kho xác nhận đơn',
  [StatusOfOrder.STORE_PICKING]: 'Quá hạn soạn hàng',
  [StatusOfOrder.STORE_PACKED]: 'Quá thời gian chờ giao hàng',
  [StatusOfOrder.BOOKED_SHIPPER]: 'Quá hạn đặt xe',
  [StatusOfOrder.SHIPPING]: 'Quá thời gian vận chuyển',
};

export enum OrderReasonCancel {
  CANCEL_BY_STORE_OUT_OF_STOCK = 'CANCEL_BY_STORE_OUT_OF_STOCK',
  CANCEL_BY_SHIPPING_NOT_FOUND_DRIVER = 'CANCEL_BY_SHIPPING_NOT_FOUND_DRIVER',
  CANCEL_BY_SHIPPING_NOT_DELIVERY_CUSTOMER = 'CANCEL_BY_SHIPPING_NOT_DELIVERY_CUSTOMER',
  CANCEL_BY_CUSTOMER_REQUEST = 'CANCEL_BY_CUSTOMER_REQUEST',
  CANCEL_BY_OTHER = 'CANCEL_BY_OTHER',
  CANCEL_BY_CUSTOMER_MISTAKE_ORDER = 'CANCEL_BY_CUSTOMER_MISTAKE_ORDER',
  CANCEL_BY_CUSTOMER_BUY_AT_OFFLINE_STORE = 'CANCEL_BY_CUSTOMER_BUY_AT_OFFLINE_STORE',
  CANCEL_BY_CUSTOMER_EXPECT_EALIER_DELIVERY = 'CANCEL_BY_CUSTOMER_EXPECT_EALIER_DELIVERY',
  CANCEL_BY_CUSTOMER_CHANGE_PAYMENT_METHOD = 'CANCEL_BY_CUSTOMER_CHANGE_PAYMENT_METHOD',
  CANCEL_BY_CUSTOMER_REPORT_PRODUCT_POOR_QUALITY = 'CANCEL_BY_CUSTOMER_REPORT_PRODUCT_POOR_QUALITY',
  CANCEL_BY_CUSTOMER_REPORT_SHIPPING_TOO_EXPENSIVE = 'CANCEL_BY_CUSTOMER_REPORT_SHIPPING_TOO_EXPENSIVE',
  CANCEL_BY_CUSTOMER_REORDER_NEW_ONE = 'CANCEL_BY_CUSTOMER_REORDER_NEW_ONE',
  CANCEL_BY_DELIVERY_MISSING_PRODUCTS = 'CANCEL_BY_DELIVERY_MISSING_PRODUCTS',
  CANCEL_BY_CUSTOMER_CHANGE_DELIVERY_ADDRESS = 'CANCEL_BY_CUSTOMER_CHANGE_DELIVERY_ADDRESS',
  CANCEL_BY_TEST_SYSTEM = 'CANCEL_BY_TEST_SYSTEM',
}

export const orderSorryVoucherReasonOptions = [
  {
    value: 'Gửi do giao hàng chậm',
    label: 'Gửi do giao hàng chậm',
  },
  {
    value: 'Gửi do KH phản ánh hàng kém chất lượng',
    label: 'Gửi do KH phản ánh hàng kém chất lượng',
  },
  {
    value: 'Gửi do KH phản ánh phí ship quá cao',
    label: 'Gửi do KH phản ánh phí ship quá cao',
  },
  {
    value: 'Gửi do kho hết hàng',
    label: 'Gửi do kho hết hàng',
  },
  {
    value: 'Đơn hàng giao sai sản phẩm',
    label: 'Đơn hàng giao sai sản phẩm',
  },
  {
    value: 'Hệ thống bị lỗi (Sai phí ship, lỗi khi đặt hàng/ thanh toán, ...)',
    label: 'Hệ thống bị lỗi (Sai phí ship, lỗi khi đặt hàng/ thanh toán, ...)',
  },
  {
    value: 'KH khiếu nại thái độ tài xế',
    label: 'KH khiếu nại thái độ tài xế',
  },
  {
    value: -1,
    label: 'Lý do khác',
  },
];

export enum PaymentStatusText {
  paid = 'Đã thanh toán',
  unpaid = 'Chưa thanh toán',
}

export enum PaymentMethod {
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
  E_WALLET = 'E_WALLET',
  CASH_VOUCHER = 'CASH_VOUCHER',
  DEBIT_CREDIT_CARD = 'DEBIT_CREDIT_CARD',
  ATM_INTERNET_BANKING = 'ATM_INTERNET_BANKING',
  UNDEFINED = 'UNDEFINED',
}

export enum PackageSize {
  STARTDARD = '',
  SIZE_1 = 'SIZE_1',
  SIZE_2 = 'SIZE_2',
  SIZE_3 = 'SIZE_3',
}

export enum ShippingService {
  'VNM-PARTNER-KFM-FT' = 'VNM-PARTNER-KFM-FT',
  'VNM-PARTNER-KFM-PT' = 'VNM-PARTNER-KFM-PT',
  'SGN-BIKE' = 'SGN-BIKE',
}

enum MembershipRank {
  MEMBER = 'MEMBER',
  SILVER = 'SILVER',
  PLATINUM = 'PLATINUM',
  GOLD = 'GOLD',
  DIAMOND = 'DIAMOND',
}

export const MembershipRankEntity: any = {
  [MembershipRank.MEMBER]: {
    label: 'Thành viên',
    value: MembershipRank.MEMBER,
    color: 'purple',
    stroke: '#FF7600',
    bgColor: '#FFFAF0',
  },
  [MembershipRank.SILVER]: {
    label: 'Bạc',
    value: MembershipRank.SILVER,
    color: '#C0C0C0',
    stroke: '#B9BABA',
    bgColor: '#FAFAFA',
  },
  [MembershipRank.GOLD]: {
    label: 'Vàng',
    value: MembershipRank.GOLD,
    color: 'yellow',
    stroke: '#D69E2E',
    bgColor: '#FFFFF0',
  },
  [MembershipRank.PLATINUM]: {
    label: 'Bạch kim',
    value: MembershipRank.PLATINUM,
    color: 'magenta',
    stroke: '#909090',
    bgColor: '#F5F5F5',
  },
  [MembershipRank.DIAMOND]: {
    label: 'Kim cương',
    value: MembershipRank.DIAMOND,
    color: 'green',
    stroke: '#4A5568',
    bgColor: '#E9E9E9',
  },
};

export enum RefundMethodOrder {
  ONE_LIFE = 'ONE_LIFE',
  HRV_CASH = 'HRV_CASH',
  HRV_MOMO_PAY = 'HRV_MOMO_PAY',
  HRV_ZALO_PAY = 'HRV_ZALO_PAY',
  MEMBERSHIP_POINT = 'MEMBERSHIP_POINT',
  HRV_INTERNET_BANKING = 'HRV_INTERNET_BANKING',
  HRV_ONE_LIFE = 'HRV_ONE_LIFE',
  OTHER = 'OTHER',
}

export enum RefundStatus {
  'PROCESSING' = 'PROCESSING',
  'INITIALIZED' = 'INITIALIZED',
  'COMPLETED' = 'COMPLETED',
}

export const RefundStatusLabel: any = {
  [RefundStatus.PROCESSING]: 'Đang xử lý',
  [RefundStatus.COMPLETED]: 'Đã hoàn thành',
  [RefundStatus.INITIALIZED]: 'Khởi tạo',
};

export const refundReasonOptions = [
  {
    value: 'Pick thiếu sản phẩm',
    label: 'Pick thiếu sản phẩm',
  },
  {
    value: 'Pick sai sản phẩm',
    label: 'Pick sai sản phẩm',
  },
  {
    value: 'Đơn hàng có Sản phẩm hết hàng',
    label: 'Đơn hàng có Sản phẩm hết hàng',
  },
  {
    value: 'Đơn hàng có Sản phẩm chênh lệch trọng lượng',
    label: 'Đơn hàng có Sản phẩm chênh lệch trọng lượng',
  },
  {
    value: 'Khách hàng yêu cầu huỷ đơn',
    label: 'Khách hàng yêu cầu huỷ đơn',
  },
  {
    value: 'Hoàn tiền do đơn group buy không thành công',
    label: 'Hoàn tiền do đơn group buy không thành công',
  },
  {
    value: 'Hoàn Voucher thành điểm',
    label: 'Hoàn Voucher thành điểm',
  },
  {
    value: -1,
    label: 'Lý do khác',
  },
];

export enum MetricProduct {
  PRODUCT_PICK_ERROR_QUALITY_DECLINE = 'PRODUCT_PICK_ERROR_QUALITY_DECLINE',
  PRODUCT_PICK_ERROR_OUT_OF_STOCK = 'PRODUCT_PICK_ERROR_OUT_OF_STOCK',
  PRODUCT_PICK_ERROR_INCORRECT_STOCK = 'PRODUCT_PICK_ERROR_INCORRECT_STOCK',
  PRODUCT_PICK_ERROR_NEAR_EXPIRED_DATE = 'PRODUCT_PICK_ERROR_NEAR_EXPIRED_DATE',
  PRODUCT_PICK_ERROR_INCORRECT_ORDERED_WEIGHT = 'PRODUCT_PICK_ERROR_INCORRECT_ORDERED_WEIGHT',
  PRODUCT_PICK_ERROR_OTHER_REASON = 'PRODUCT_PICK_ERROR_OTHER_REASON',
}

export const MetricProductOptions = [
  {
    id: -1,
    name: 'Tất cả',
  },
  {
    id: MetricProduct.PRODUCT_PICK_ERROR_QUALITY_DECLINE,
    name: 'Sản phẩm kém chất lượng',
  },
  {
    id: MetricProduct.PRODUCT_PICK_ERROR_OUT_OF_STOCK,
    name: 'Sản phẩm hết hàng',
  },
  {
    id: MetricProduct.PRODUCT_PICK_ERROR_INCORRECT_STOCK,
    name: 'Sản phẩm sai tồn kho',
  },
  {
    id: MetricProduct.PRODUCT_PICK_ERROR_NEAR_EXPIRED_DATE,
    name: 'Sản phẩm cận date',
  },
  {
    id: MetricProduct.PRODUCT_PICK_ERROR_OTHER_REASON,
    name: 'Lý do khác',
  },
];


export enum HeavyProduct {
  STANDARD = 'STANDARD',
  SIZE_1 = 'SIZE_1',
  SIZE_2 = 'SIZE_2',
  SIZE_3 = 'SIZE_3',
}

export const HeavyProductOptions: any = [
  {
    value: HeavyProduct.STANDARD,
    label: (
      <span>
        <span className="font-bold">Thông thường</span> (50 x 40 x 50 : 30kg - Miễn phí)
      </span>
    ),
  },
  {
    value: HeavyProduct.SIZE_1,
    label: (
      <span>
        <span className="font-bold">Mức 1</span> (60 x 50 x 60 : 40kg - 10.000đ)
      </span>
    ),
  },
  {
    value: HeavyProduct.SIZE_2,
    label: (
      <span>
        <span className="font-bold">Mức 2</span> (70 x 60 x 70 : 60kg - 20.000đ)
      </span>
    ),
  },
  {
    value: HeavyProduct.SIZE_3,
    label: (
      <span>
        <span className="font-bold">Mức 3</span> (90 x 90 x 90 : 80kg - 40.000đ)
      </span>
    ),
  },
];

export const ResetOrderStatusOptions: any = [
  {
    value: StatusOfOrder.NEW,
    label: 'Mới',
  },
  {
    value: StatusOfOrder.ASSIGNED,
    label: 'Đã phân công',
  },
  {
    value: StatusOfOrder.CONFIRMED,
    label: 'Đã xác nhận',
  },
  {
    value: StatusOfOrder.STORE_PICKING,
    label: 'Đang soạn hàng',
  },
  {
    value: StatusOfOrder.STORE_PACKED,
    label: 'Soạn hàng xong',
  },
];
