import { SearchOutlined } from '@ant-design/icons';
import { DatePicker, Input } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'react-use';
import IPagination from '~/components/antd/IPagination';
import TableAntCustom from '~/components/antd/ITable/TableAntCustom';
import { SCard } from '~/components/commons';
import CustomerRank from '~/components/commons/CustomerRank';
import CustomerPopOver from '~/components/shared/_molecules/CustomerPopOver/CustomerPopOver';
import { usePaging } from '~/hooks/usePaging';
import { useSearchCustomers } from '~/queries/customer/useSearchCustomers';

const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'Mã KH',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Khách hàng',
    dataIndex: 'name',
    key: 'name',
    render: (name: string, record: any) => {
      return (
        <div className="flex flex-col gap-1">
          <CustomerPopOver phoneNumber={record?.phone}>
            <Link to={`/customer/customer-detail/${record?.phone}`}>{name}</Link>
          </CustomerPopOver>
          <div>
            <CustomerRank rank={record?.membership?.rank}>({record?.numberCompletedOrders})</CustomerRank>
          </div>
        </div>
      );
    },
  },
  {
    title: 'SĐT',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'deliveryAddresses',
    key: 'deliveryAddresses',
    render: (deliveryAddresses: any) => {
      return <>{deliveryAddresses?.[0]?.address}</>;
    },
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdTime',
    key: 'createdTime',
    render: (text: number) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
  },
];

const ListCustomer = () => {
  const [createdTimeRange, setCreatedTimeRange] = useState('');
  const [keyword, setKeyWord] = useState('');
  const {
    actions: { setPage, onChangePagination },
    states: { sizer, currentPage },
  } = usePaging({ sizer: 10 });

  const isInitRef = useRef(true);

  const [] = useDebounce(
    () => {
      setPage(1);
      fetchCustomer();
    },
    500,
    [keyword, createdTimeRange]
  );

  const {
    data,
    isFetching,
    refetch: fetchCustomer,
  } = useSearchCustomers({
    pageIndex: currentPage,
    limit: sizer,
    keyword,
    createdTimeRange,
  });

  useEffect(() => {
    if (!isInitRef.current) {
      fetchCustomer();
    }
    isInitRef.current = false;
  }, [currentPage]);

  const customers = data?.list;

  return (
    <SCard className="p-5" rounded>
      <div className="mb-4 flex gap-3 justify-end">
        <Input
          allowClear
          onChange={(e: any) => setKeyWord(e.target.value)}
          style={{ width: 400 }}
          suffix={<SearchOutlined />}
          placeholder="Tìm theo tên, số điện thoại khách hàng"
        />
        <RangePicker
          format="DD-MM-YYYY"
          placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
          onChange={(dates: any) => {
            if (!dates) {
              return setCreatedTimeRange('');
            }
            const startDateTransform = moment(
              moment(Number(moment(dates[0]).valueOf())).startOf('day')
            ).valueOf();
            const endDateTransform = moment(
              moment(Number(moment(dates[1]).valueOf())).endOf('day')
            ).valueOf();
            setCreatedTimeRange(startDateTransform + '-' + endDateTransform);
          }}
        />
      </div>
      <TableAntCustom
        pagination={false}
        loading={isFetching}
        dataSource={customers || []}
        columns={columns}
      />
      <IPagination
        hiddenPerPage
        size={sizer}
        total={data?.total || 0}
        current={currentPage}
        onChange={onChangePagination}
      />
    </SCard>
  );
};

export default ListCustomer;
