import { useQuery } from 'react-query';
import axiosClient from '~/config/axiosClient';

const getOrderRatingTicketStatusCounters = async (filters: any) => {
  return await axiosClient.get('/order/getOrderRatingTicketStatusCounters', {
    params: { ...filters },
  });
};

export const useGetOrderRatingTicketStatusCounters = (
  filters?: any,
  key?: any,
  config = { enabled: true }
) => {
  return useQuery(
    [key || 'getOrderRatingTicketStatusCounters'],
    () => getOrderRatingTicketStatusCounters(filters),
    {
      ...config,
    }
  );
};
