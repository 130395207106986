import { Modal } from 'antd';
import React from 'react';

const OrderResersesModal = ({ isModalOpen, orderReserses, onCancel }) => {
  return (
    <Modal
      footer={false}
      title={'Order Reserve'}
      visible={isModalOpen}
      width={450}
      onCancel={onCancel}
      maskClosable={false}
      bodyStyle={{ padding: 20 }}
    >
      <div>
        {Object.keys(orderReserses || {})?.length ? (
          <div className="overflow-auto" style={{ maxHeight: '500px' }}>
            <table
              border={1}
              style={{ borderColor: '#dfdfdf', width: '100%' }}
              className="w-100"
              cellPadding={3}
            >
              <tr>
                <th className="px-2" style={{ width: '35%' }}>
                  Mã đơn
                </th>
                <th className="px-2" style={{ width: '765%' }}>
                  Reserve
                </th>
              </tr>
              {Object.keys(orderReserses || {})?.map((key) => {
                return (
                  <>
                    <tr>
                      <td className="px-2">{key}</td>
                      <td className="px-2">{orderReserses[key]}</td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>
        ) : undefined}
      </div>
    </Modal>
  );
};

export default OrderResersesModal;
