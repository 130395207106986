import React from 'react';
import OrderBagsContextProvider from './context';
import OrderBags from './OrderBags';

const OrderBagsWrapper = () => {
  return (
    <OrderBagsContextProvider>
      <OrderBags />
    </OrderBagsContextProvider>
  );
};

export default OrderBagsWrapper;
