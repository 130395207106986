import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserInfoReset } from '~/hooks/useUserInfo';
import { AuthContext } from '~/contexts/AuthContext';

export const useLogout = () => {
  const { resetUser } = useUserInfoReset();
  const { setIsAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const onLogout = () => {
    setIsAuth(false);
    navigate('/login');
    resetUser();
  };

  return {
    onLogout,
  };
};
