import React, { useEffect } from 'react';
import CouponEditOrder from './components/CouponEditOrder';
import ProductsFormDrawer from './components/Drawer/ProductsFormDrawer';
import CouponDetailProvider from './contexts/CouponDetailContext';
import CouponDrawerProvider from './contexts/CouponDrawerContext';
import ProductCouponDetailProvider from './contexts/ProductCouponDetailContext';

const CouponDetail = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <CouponDetailProvider>
      <ProductCouponDetailProvider>
        <CouponDrawerProvider>
          <CouponEditOrder />
          {/* <RelatedProducts /> */}

          {/* Drawer */}
          <ProductsFormDrawer />
        </CouponDrawerProvider>
      </ProductCouponDetailProvider>
    </CouponDetailProvider>
  );
};

export default CouponDetail;
