import {
  DeleteOutlined,
  LoadingOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Col, Empty, Image, Input, Row, Space, Spin, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { useDebounce } from 'react-use';
import CurrencyFormat from '~/components/commons/CurrencyFormat';
import { fallbackImage } from '~/contants/commons';
import { useGetProductsByStore } from '~/queries/product/useGetProductsByStore';
import InputAddCart from '~/components/shared/_molecules/InputAddCart';
import ProductItem from '~/components/shared/_molecules/ProductItem';

const antIcon = <LoadingOutlined spin />;

const ProductCartItem = ({
  quantity = 1,
  name,
  image,
  originPrice,
  sellPrice,
  onPlus,
  onMinus,
  onRemoveProduct,
  onChangeAmount,
}: {
  quantity?: number;
  name?: string;
  image?: string;
  originPrice?: number;
  sellPrice?: number;
  onPlus?: () => void;
  onMinus?: () => void;
  onRemoveProduct?: () => void;
  onChangeAmount?: (amount: number) => void;
}) => {
  return (
    <>
      <div className="flex items-center gap-3">
        <Image
          fallback={fallbackImage}
          preview={false}
          width={100}
          src={image || 'error'}
          style={{ objectFit: 'contain' }}
        />
        <div className="flex-col gap-4">
          <h3 className="mb-0 truncate text-ellipsis" style={{ width: '170px' }}>
            {name || 'Thịt heo sườn ba'}
          </h3>
          <div className="flex mb-2">
            <span className="mr-2 font-medium">
              <CurrencyFormat number={originPrice || 0} />
            </span>
            {originPrice !== sellPrice && (
              <span className="line-through text-gray-500">
                <CurrencyFormat number={sellPrice || 0} />
              </span>
            )}
          </div>
          <InputAddCart
            onChangeAmount={onChangeAmount}
            onMinus={onMinus}
            onPlus={onPlus}
            amount={quantity}
          />
        </div>
      </div>
      <div className="cursor-pointer">
        <DeleteOutlined
          onClick={onRemoveProduct}
          style={{ fontSize: '20px', color: 'var(--primary-color)' }}
        />
      </div>
    </>
  );
};

type Props = {
  products?: Array<Product>;
  storeCode?: string;
  fullAddressStore?: string;
  onAddProduct?: ({ product, amount }: { product: Product; amount: number }) => void;
  onRemoveProduct?: (product: any) => void;
  onDecreaseQuantity?: (barcode: string) => void;
  onIncreaseQuantity?: (barcod: string) => void;
  onChangeAmount?: ({ amount, barcode }: { amount: number; barcode: string }) => void;
};

const ProductsForm: React.FC<Props> = ({
  products,
  storeCode,
  onAddProduct,
  onRemoveProduct,
  onDecreaseQuantity,
  onIncreaseQuantity,
  onChangeAmount,
}) => {
  const [value, setValue] = useState<string>('');
  const refInit = useRef(true);
  const [] = useDebounce(
    () => {
      refetchProductStore();
    },
    500,
    [value]
  );

  const {
    data: productSelection,
    isFetching,
    refetch: refetchProductStore,
  } = useGetProductsByStore(
    {
      storeCode,
      keyword: value,
    },
    { enabled: false }
  );

  useEffect(() => {
    if (!refInit.current) {
      refetchProductStore();
    }
    refInit.current = false;
  }, []);

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <Row className="min-h-full">
      <Col
        span={19}
        className="py-3"
        style={{
          border: '1px solid transparent',
          maxHeight: '94vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Row>
          <Col span={24}>
            <Tag color="blue">{storeCode}</Tag>
          </Col>
          <Col span={24}>
            <div className="w-100 flex items-center gap-10 px-2">
              <Input
                className="my-3 rounded"
                placeholder="Tìm kiếm sản phẩm theo tên, mã sản phẩm, barcode"
                style={{ width: '100%' }}
                size="large"
                onChange={handleChange}
                suffix={isFetching && <LoadingOutlined />}
                prefix={<SearchOutlined />}
                allowClear
              />
              <div className="cursor-pointer mr-4">
                <Badge count={products?.length || 0}>
                  <Avatar
                    style={{ backgroundColor: 'transparent' }}
                    shape="circle"
                    icon={
                      <ShoppingCartOutlined
                        style={{ fontSize: '26px', color: 'var(--primary-color)' }}
                      />
                    }
                  />
                </Badge>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {!isFetching && !productSelection?.error && (
            <>
              {productSelection?.length ? (
                productSelection?.map((product: Product, index: number) => (
                  <Col xl={4} lg={5} key={product.barcode + index}>
                    <div className="px-1">
                      <ProductItem
                        {...product}
                        onAdd={({ amount }) => {
                          onAddProduct?.({ amount: Number(amount), product });
                        }}
                      />
                    </div>
                  </Col>
                ))
              ) : (
                <div className="text-center w-100">
                  <Empty description="Không tìm thấy sản phẩm" />
                </div>
              )}
            </>
          )}
          {isFetching && (
            <div className="flex justify-center items-center w-100 mt-5">
              <Spin tip="Đang tải ..." indicator={antIcon} />
            </div>
          )}
          {productSelection?.error && (
            <div className="flex justify-center items-center w-100 mt-5">
              <span className="text-center">Xảy ra lỗi không xác định</span>
            </div>
          )}
        </Row>
      </Col>
      <Col
        span={5}
        className="mt-5 pt-5 flex"
        style={{
          border: '1px solid transparent',
          maxHeight: '94vh',
          overflow: 'auto',
          marginTop: '-140px',
        }}
      >
        <div
          className="border-l-1 ml-3 border-t-0 border-r-0 border-b-0 border-1 border-gray-300 h-full"
          style={{ width: '1px', background: '#dfdfdf' }}
        ></div>
        <Space direction="vertical" size={10} className="w-100 mt-3 pl-3 pr-1">
          <div className="flex justify-between">
            <strong>Giỏ hàng</strong>
            <span>Số lượng: {products?.length || 0} sp</span>
          </div>
          {products?.map((product: any, key: number) => {
            return (
              <div className="flex justify-between items-center mt-5 pt-2">
                <ProductCartItem
                  onChangeAmount={(amount) =>
                    onChangeAmount?.({ amount, barcode: product?.barcode })
                  }
                  onMinus={() => onDecreaseQuantity?.(product.barcode)}
                  onPlus={() => onIncreaseQuantity?.(product.barcode)}
                  onRemoveProduct={() => onRemoveProduct?.(product)}
                  {...product}
                  key={key}
                />
              </div>
            );
          })}
        </Space>
      </Col>
    </Row>
  );
};

export default ProductsForm;
