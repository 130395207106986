import { Button, Card, Col, Form, Input, InputNumber, Row, Space, Switch, message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductSelection from '~/components/shared/ProductSelection/ProductSelection';
import { SERVER_PREFIX } from '~/contants/commons';
import { useCreatePreOrderStockMutation } from '~/queries/inventory/useCreatePreOrderStockMutation';

const InventoryProductStockAvailableEdit = () => {
  const navigate = useNavigate();

  const [unitName, setUnitName] = useState('--');
  const [isQuantityMin, setIsQuantityMin] = useState(true);

  const [form] = Form.useForm<{
    barcode?: string;
    quantityMin?: number;
    quantityInit?: string;
  }>();

  const { mutate: createPreOrderStockMutation, isSuccess, data } = useCreatePreOrderStockMutation();

  useEffect(() => {
    if (data?.error) {
      message.error(SERVER_PREFIX + data?.error);
    } else if (isSuccess) {
      message.success('Tạo Stock PreOrder thành công');
      navigate(-1);
    }
  }, [isSuccess]);

  const handleSave = (values: any) => {
    if (!Boolean(form.getFieldValue('barcode'))) {
      message.error('Vui lòng chọn sản phẩm');
    }
    let newValue = { ...values };
    if (!isQuantityMin) {
      delete newValue.quantityMin;
    }
    const jsonSubmit = {
      ...newValue,
    };
    createPreOrderStockMutation(jsonSubmit);
  };

  const handleSelectProduct = (value: any, product: any) => {
    setUnitName(product?.unit);
    form.setFieldsValue({
      barcode: value,
    });
  };

  const handleToggleQuantityMin = (value) => {
    setIsQuantityMin(value);
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={handleSave}
      form={form}
      autoComplete="off"
    >
      <div className="flex justify-center mt-3 ">
        <Card bordered={false} className="w-5/12 ">
          <Space direction="vertical" className="w-full">
            <div className="flex flex-col">
              <label className="font-bold">Sản phẩm</label>
              <div className="mt-1">
                <ProductSelection onSelectProduct={handleSelectProduct} />

                <Form.Item
                  name="barcode"
                  className="hidden"
                  rules={[{ required: true, message: 'Trường này bắt buộc!' }]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
            <small>Đơn vị của sản phẩm sẽ tính theo base unit</small>
            <Row gutter={15}>
              <Col span={24}>
                <div className="flex flex-col">
                  <label className="font-bold">SL khởi tạo</label>
                  <Form.Item
                    name="quantityInit"
                    rules={[{ required: true, message: 'Trường này bắt buộc!' }]}
                  >
                    <InputNumber className="w-full" addonAfter={unitName || ''} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex flex-col">
                  <label className="font-bold w-full">
                    <div className="flex justify-between">
                      <span>Tồn kho tối thiểu</span>
                      <Switch
                        onChange={handleToggleQuantityMin}
                        size="small"
                        checked={isQuantityMin}
                      />
                    </div>
                  </label>
                  <Form.Item name="quantityMin">
                    <InputNumber min={0} className="w-full" disabled={!isQuantityMin} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Form.Item className="w-100 " noStyle>
              <div className="float-right">
                <Button
                  className="mr-3 "
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Trở lại
                </Button>
                <Button type="primary" htmlType="submit">
                  Lưu
                </Button>
              </div>
            </Form.Item>
          </Space>
        </Card>
      </div>
    </Form>
  );
};

export default InventoryProductStockAvailableEdit;
