import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

import { SLA } from '~/modules/system-setting/pages/SLASetting/SLASettingList/SLASettingList';

const updateConfigSLA = async (slaObj?: SLA) => {
  return await axiosClient.post<any>(`config/updateConfigSlaOverdue`, { config: { ...slaObj } });
};

export const useOrderUpdateMutation = () => {
  return useMutation({
    mutationFn: (slaObj?: SLA) => updateConfigSLA(slaObj),
  });
};
