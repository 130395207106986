import React from 'react';

var classNames = require('classnames');

const SCard = ({ children, rounded, className, ...resProps }: any) => {
  return (
    <div
      className={classNames('s-card  shadow-lg p-3 bg-white', { rounded }, className)}
      {...resProps}
    >
      {children}
    </div>
  );
};

export default SCard;
