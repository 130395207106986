import { useQuery } from 'react-query';
import axiosClient from '~/config/axiosClient';

export interface FilterSearchProduct {
  keyword?: string;
  sortType?: 1 | -1;
  pageIndex?: number;
  limit?: number;
  sortBy?: string;
}

const searchProductVariants = async (filter: FilterSearchProduct) => {
  const params = {
    filter: JSON.stringify({ ...filter }),
  };
  return await axiosClient.get('product/searchProductVariants', {
    params,
  });
};

export const useSearchProductVariants = (
  filter: FilterSearchProduct,
  config = { enabled: true }
) => {
  return useQuery(['searchProductVariants', filter], () => searchProductVariants(filter), {
    ...config,
  });
};
