import React, { useContext, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

type Props = {
  children: React.ReactNode;
};

export const SidebarContext = React.createContext<any>({
  navs: [],
  isOpenSidebar: true,
});
const SidebarProvider: React.FC<Props> = ({ children }) => {
  const [value, setValue] = useLocalStorage('isOpenSidebar');
  const [isOpenSidebar, setIsOpenSidebar] = useState(Boolean(value));

  useEffect(() => {
    setValue(isOpenSidebar);
  }, [isOpenSidebar, setValue]);

  const valueContext = React.useMemo(
    () => ({
      isOpenSidebar,
      setIsOpenSidebar,
    }),
    [isOpenSidebar, setIsOpenSidebar]
  );

  return <SidebarContext.Provider value={valueContext}>{children}</SidebarContext.Provider>;
};

export default SidebarProvider;

export const useSidebarContext = () => useContext(SidebarContext);
