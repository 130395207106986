import { Modal } from 'antd';
import TableAntCustom from '~/components/antd/ITable/TableAntCustom';

const { confirm } = Modal;

const columns = [
  {
    title: 'Barcode',
    dataIndex: 'barcode',
    key: 'barcode',
  },
  {
    title: 'Tên SP',
    dataIndex: 'productName',
    key: 'productName',
  },
  {
    title: 'Đơn vị',
    dataIndex: 'unit',
    key: 'unit',
    render: (unit: any) => {
      return <span>{unit?.name}</span>;
    },
  },
  {
    title: 'SL SP đã đặt',
    dataIndex: 'quantityOrdered',
    key: 'quantityOrdered',
  },
];

function VariantDistributions({ variantDistributions, totalNumberOrders }: any) {
  return (
    <div>
      <TableAntCustom
        pagination={false}
        loading={false}
        columns={columns}
        dataSource={variantDistributions}
      />
    </div>
  );
}

export default VariantDistributions;
