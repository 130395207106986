import { useMutation } from 'react-query';
import axiosClient from '~/config/axiosClient';

const login = async () => {
  return await axiosClient.post('auth/genHRVLoginURL');
};

export const useLoginQuery = ({ onError, onSuccess }: any) => {
  return useMutation(() => login(), {
    cacheTime: 0,
    onError,
    onSuccess: <TData>(data: TData) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });
};
