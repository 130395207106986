import axiosClient from '~/config/axiosClient';
import { useQuery } from 'react-query';

const getNotification = async (channelId: string, topic: string) => {
  return await axiosClient.get<OrderListTypes>(`notification/subscribe?channelId=${channelId}&topic=${topic}`);
};

export const useGetNotificationQuery = ({ channelId, topic, config = {} }: any) => {
  let newChannelId = channelId;
  if (!channelId) {
    newChannelId = (Math.random() + 1).toString(36).substring(7);
    localStorage.setItem('channelId', JSON.stringify(newChannelId));
  }
  return useQuery(['notification', channelId, topic], () => getNotification(newChannelId, topic), {
    ...config,
    // cacheTime: 0,
    // staleTime: 0,
    // refetchInterval: 500,
  });
};
