import { MinusCircleOutlined, PlusCircleOutlined, ShoppingOutlined } from '@ant-design/icons';
import { Affix, Badge, Button, Col, Empty, Form, Input, Row, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import Loading from '~/components/loading';
import { SERVER_PREFIX } from '~/contants/commons';
import { useUpdateOrderBags } from '~/queries/commons/updateOrderBags';
import { useGetBagLabelImages } from '~/queries/commons/useGetBagLabelImages';
import { SHARE_SECRET_KEY } from '~/queries/orders/useOrderDetailQuery';
import { OrderBagCode, OrderBagLabel, OrderBagType } from '../types';
import { useOrderBagsContext } from './context';
import './styles.scss';

declare var messageHandler: any;

const key = 'updatable';

const Item = ({
  code,
  type,
  onRemoveItemBag,
  onPrintLabel,
  loadingOfLabel,
  bagLabelImagesLoading,
}) => {
  return (
    <Row align="middle" gutter={15} className="mt-5">
      <Col span={14}>
        <div className="flex gap-2 items-center">
          <ShoppingOutlined style={{ color: 'gray', fontSize: 19 }} />
          <span className="font-medium font-weight-bold text-gray-500">{code}</span>
        </div>
      </Col>
      <Col span={8}>
        <Button
          loading={loadingOfLabel?.[code]}
          type="primary"
          ghost
          size="small"
          disabled={!!Object.keys(loadingOfLabel).length || bagLabelImagesLoading}
          onClick={() => onPrintLabel(code)}
        >
          IN LABEL
        </Button>
      </Col>
      <Col span={2}>
        <div className="cursor-pointer flex items-center">
          <Button
            onClick={() => onRemoveItemBag({ type, code })}
            icon={<MinusCircleOutlined style={{ color: 'red', fontSize: 19 }} />}
            type="text"
          ></Button>
        </div>
      </Col>
    </Row>
  );
};

const TypeBagItem = ({
  label,
  type,
  data,
  onRemoveAllItemType,
  onRemoveItemBag,
  onPrintLabel,
  loadingOfLabel,
  bagLabelImagesLoading,
}) => {
  if (!data.length) return <></>;
  return (
    <div className="bg-white px-3 py-5 mt-4">
      <Row align="middle" gutter={15}>
        <Col span={14}>
          <span className="font-medium font-weight-bold" style={{ fontSize: 16 }}>
            {label}
          </span>
        </Col>
        <Col span={8}>
          <div className="flex items-center gap-1 text-gray-500">
            <span className="font-medium">{data?.length || 0}</span>
            <ShoppingOutlined style={{ fontSize: 20 }} />
          </div>
        </Col>
        <Col span={2}>
          <div className="cursor-pointer flex item">
            <Button
              onClick={() => onRemoveAllItemType(type)}
              icon={<MinusCircleOutlined style={{ color: 'red', fontSize: 19 }} />}
              type="text"
            ></Button>
          </div>
        </Col>
      </Row>
      {data?.length ? (
        data?.map(({ code }) => {
          return (
            <React.Fragment key={code}>
              <Item
                {...{
                  code,
                  type,
                  onRemoveItemBag,
                  onPrintLabel,
                  loadingOfLabel,
                  bagLabelImagesLoading,
                }}
              />
            </React.Fragment>
          );
        })
      ) : (
        <>
          <Empty description={<span>Không có dữ liệu</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </>
      )}
    </div>
  );
};

const OrderBagForm = ({ onSubmitBag }) => {
  const [form] = Form.useForm<{
    type: any;
    number: number;
  }>();

  useEffect(() => {
    form.setFieldsValue({
      type: OrderBagType.FROZEN,
      number: 1,
    });
  }, [form]);

  const handleSubmit = (values) => {
    onSubmitBag?.(values);
    form.setFieldsValue({
      number: 1,
    });
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={{ number: 1, type: OrderBagType.FROZEN }}
    >
      <div className="bg-white px-3 py-2 pb-5">
        <Row align="middle" gutter={15}>
          <Col span={14}>
            <div className="flex flex-col">
              <label className="font-medium text-gray-500 mb-1">Chọn loại túi</label>
              <Form.Item name="type" noStyle>
                <Select defaultValue={form.getFieldValue('type')}>
                  <Select.Option value={OrderBagType.FROZEN}>Hàng đông lạnh</Select.Option>
                  <Select.Option value={OrderBagType.FRESH}>Hàng tươi mát</Select.Option>
                  <Select.Option value={OrderBagType.DRY}>Hàng khô</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={8}>
            <div className="flex flex-col">
              <label className="font-medium text-gray-500 mb-1">Nhập số túi</label>
              <Form.Item name="number" noStyle>
                <Input
                  type="number"
                  defaultValue={form.getFieldValue('number')}
                  min={0}
                  allowClear
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={2}>
            <div className="flex flex-col items-center">
              <label className="font-medium">&nbsp;</label>
              <Form.Item noStyle>
                <Button
                  htmlType="submit"
                  icon={<PlusCircleOutlined style={{ color: 'green', fontSize: 19 }} />}
                  type="text"
                ></Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

const OrderBags = () => {
  const [searchParams] = useSearchParams();

  const orderCode: any = searchParams.get('orderCode');
  const employeeName = searchParams.get('employeeName');

  const [hasPrint, setHasPrint] = useState(false);
  const [hasPrintAll, setHasPrintAll] = useState(false);
  const [bagCodes, setBagsCodes] = useState<any>([]);
  const [loadingOfLabel, setLoadingOfLabel] = useState({});

  const { orderBags, orderDetail, onSetOrderBags, isLoadingOrderDetail, isChangeBags } =
    useOrderBagsContext();

  console.log(bagCodes, 'bagCodes');

  const {
    data: bagLabelImages,
    refetch: getBagLabelImages,
    isFetching: bagLabelImagesLoading,
  } = useGetBagLabelImages(
    {
      orderCode,
      bagCodes,
    },
    {
      enabled: false,
      onSuccess: (bagLabelImages) => {
        setLoadingOfLabel({});
        if (bagLabelImages?.error) {
          message.success(bagLabelImages?.error);
          return;
        }
        const messagePrint = !!bagCodes.length
          ? ` Đã in túi ${bagCodes}`
          : `Đã in tất cả túi thành công`;
        message.success(messagePrint);
        try {
          if (messageHandler) {
            messageHandler.postMessage(
              JSON.stringify({
                action: 'PRINT_PICK_PACK_LABEL',
                data: [...bagLabelImages],
              })
            );
          }
        } catch {}
        return bagLabelImages;
      },
    }
  );

  const { header } = orderDetail || {};
  const { statusName } = header || {};

  const queryClient = useQueryClient();

  const handleSubmitBag = ({ type, number }) => {
    if (orderBags[type]?.length) {
      message.warning(`Danh sách ${OrderBagLabel[type]} đã tồn tại`);
      return;
    }
    const bags = new Array(+number).fill(0).map((_, index) => {
      const pos = index + 1;
      const code = `${orderCode}-${OrderBagCode[type]}${pos < 10 ? `0${pos}` : pos}`;
      return {
        code,
        type,
      };
    });

    onSetOrderBags?.((currentState) => ({
      ...currentState,
      [type]: [...bags],
    }));

    message.success('Thêm thành công');
  };

  const handleRemoveAllItemType = (type) => {
    onSetOrderBags?.((currentState) => ({
      ...currentState,
      [type]: [],
    }));
  };

  const handleRemoveItemBag = ({ type, code }) => {
    const typeInBags = orderBags[type];
    const typeInBagsRemoved = typeInBags.filter((typeInBag) => typeInBag.code !== code);
    onSetOrderBags?.((currentState) => ({
      ...currentState,
      [type]: typeInBagsRemoved,
    }));
  };

  const handlePrintLabel = (code: any) => {
    setBagsCodes([code]);
    setTimeout(() => {
      if (isChangeBags) {
        setHasPrint(true);
        handleUpdateBags();
      } else {
        setLoadingOfLabel({ [code]: true });
        getBagLabelImages();
      }
    });

    // if (isChangeBags) {
    //   setHasPrint(true);
    //   setBagCode(code);
    //   handleUpdateBags();
    // } else {
    //   navigator(`/kdb/label-order-bags?orderCode=${orderCode}&bagCode=${code}`);
    // }
  };

  const handlePrintAllLabel = () => {
    setBagsCodes([]);
    setTimeout(() => {
      if (isChangeBags) {
        setHasPrintAll(true);
        handleUpdateBags();
      } else {
        getBagLabelImages();
      }
    });

    // if (isChangeBags) {
    //   setHasPrintAll(true);
    //   handleUpdateBags();
    // } else {
    //   navigator(`/kdb/label-order-bags?orderCode=${orderCode}`);
    // }
  };

  const totalBags = orderBags?.DRY?.length + orderBags?.FRESH?.length + orderBags?.FROZEN?.length;

  const handleUpdateBags = () => {
    if (totalBags === 0) {
      message.warning('Vui lòng nhập số túi');
      return;
    }
    const mergeOrderBags = [...orderBags.DRY, ...orderBags.FRESH, ...orderBags.FROZEN];
    message.loading({
      key,
      content: 'Đang hoàn tất...',
    });

    updateOrderBags({
      data: mergeOrderBags,
      secretKey: SHARE_SECRET_KEY,
      orderCode,
      employee: {
        name: employeeName,
      },
    });
  };

  const {
    mutate: updateOrderBags,
    isSuccess: isSuccessUpdateOrderBags,
    data: updateOrderBagsRes,
    isLoading: isFetchingUpdateOrderBag,
  } = useUpdateOrderBags({});

  useEffect(() => {
    if (updateOrderBagsRes?.error) {
      message.destroy(key);
      message.error(SERVER_PREFIX + updateOrderBagsRes?.error);
    } else if (updateOrderBagsRes) {
      message.success({ content: 'Cập nhập thành công!', key, duration: 2 });
      setTimeout(() => {
        if (hasPrintAll) {
          setHasPrintAll(false);
          getBagLabelImages();
          // navigator(`/kdb/label-order-bags?orderCode=${orderCode}`);
        }
        if (hasPrint) {
          setLoadingOfLabel({ [bagCodes]: true });
          setHasPrint(false);
          getBagLabelImages();
          // navigator(`/kdb/label-order-bags?orderCode=${orderCode}&bagCode=${bagCodes}`);
        }
      }, 500);
      queryClient.invalidateQueries(['orderShareDetail']);
    }
  }, [isSuccessUpdateOrderBags]);

  if (isLoadingOrderDetail) return <Loading />;

  return (
    <div className="order-bags">
      <>
        <div className="header flex gap-2 items-center justify-between px-4">
          <div className="__left flex gap-2 items-center cursor-pointer">
            <span className="font-medium ml-5" style={{ fontSize: 17 }}>
              {orderCode} - Nhập số túi
            </span>
          </div>
          <div className="__right flex gap-2 items-center">
            <span className="font-bold">{totalBags}</span>
            <ShoppingOutlined style={{ fontSize: 20 }} />
          </div>
        </div>
        <div className="content-scroll pb-4">
          <Affix offsetTop={65}>
            <div className="bg-white flex py-1 mb-3 pl-2 text-xs">
              <Badge color="rgb(45, 183, 245)" text={statusName} />
            </div>
            <OrderBagForm onSubmitBag={handleSubmitBag} />
          </Affix>
          {totalBags === 0 && (
            <div className="text-right mt-3 mr-3 font-medium text-gray-500">
              Ấn nút{' '}
              <PlusCircleOutlined className="mx-2" style={{ color: 'green', fontSize: 16 }} /> để
              thêm túi
            </div>
          )}
          <TypeBagItem
            label={OrderBagLabel.FROZEN}
            type={OrderBagType.FROZEN}
            onRemoveAllItemType={handleRemoveAllItemType}
            onRemoveItemBag={handleRemoveItemBag}
            onPrintLabel={handlePrintLabel}
            loadingOfLabel={loadingOfLabel}
            bagLabelImagesLoading={bagLabelImagesLoading}
            data={orderBags?.[OrderBagType.FROZEN]}
          />
          <TypeBagItem
            label={OrderBagLabel.FRESH}
            type={OrderBagType.FRESH}
            onRemoveAllItemType={handleRemoveAllItemType}
            onRemoveItemBag={handleRemoveItemBag}
            onPrintLabel={handlePrintLabel}
            loadingOfLabel={loadingOfLabel}
            bagLabelImagesLoading={bagLabelImagesLoading}
            data={orderBags?.[OrderBagType.FRESH]}
          />
          <TypeBagItem
            label={OrderBagLabel.DRY}
            type={OrderBagType.DRY}
            onRemoveAllItemType={handleRemoveAllItemType}
            onRemoveItemBag={handleRemoveItemBag}
            onPrintLabel={handlePrintLabel}
            loadingOfLabel={loadingOfLabel}
            bagLabelImagesLoading={bagLabelImagesLoading}
            data={orderBags?.[OrderBagType.DRY]}
          />
        </div>
        <div className="footer flex justify-between">
          <Button
            onClick={handlePrintAllLabel}
            loading={!Object.keys(loadingOfLabel).length && bagLabelImagesLoading}
            style={{
              flex: 1,
              borderRadius: 0,
              opacity: Object.keys(loadingOfLabel).length && bagLabelImagesLoading ? 0.3 : 1,
            }}
            className="bg-green-500 h-full"
            type="text"
          >
            <span className="text-white text-lg">IN TẤT CẢ</span>
          </Button>

          <Button
            onClick={handleUpdateBags}
            // disabled={!isChangeBags}
            loading={isFetchingUpdateOrderBag}
            style={{ flex: 1, borderRadius: 0 }}
            className="bg-orange-500 h-full"
            type="text"
          >
            <span className="text-white text-lg">CẬP NHẬT</span>
          </Button>
        </div>
      </>
    </div>
  );
};

export default OrderBags;
