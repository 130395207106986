import { useQuery } from 'react-query';
import axiosClient from '~/config/axiosClient';

const getProfile = async (customerPhone: string) => {
  const params = {
    customerPhone,
  };
  return await axiosClient.get('/customer/getProfile', { params });
};

export const useGetProfileCustomer = (phone: string, config = { enabled: true }) => {
  return useQuery(['getProfile'], () => getProfile(phone), {
    ...config,
  });
};
