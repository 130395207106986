import React, { createContext, useContext, useMemo } from 'react';
import useCouponDrawer, { PopupCouponType } from '../hooks/useCouponDrawer';

type CouponDetailContextType = PopupCouponType;

const CouponDrawerContext = createContext<CouponDetailContextType>({});

type Props = {
  children: React.ReactNode;
};

const CouponDrawerProvider: React.FC<Props> = ({ children }) => {
  const { states: statePopups, actions: actionPopups } = useCouponDrawer();

  const valueContext: CouponDetailContextType = useMemo(
    () => ({
      ...statePopups,
      ...actionPopups,
    }),
    [statePopups]
  );

  return (
    <CouponDrawerContext.Provider value={valueContext}>{children}</CouponDrawerContext.Provider>
  );
};

export default CouponDrawerProvider;

export const useCouponDrawerContext = () => useContext(CouponDrawerContext);
