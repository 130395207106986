import { message } from 'antd';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
  ERROR_AUTH_TOKEN_REQUIRED,
  ERROR_AUTH_TOKEN_EXPIRED,
  ERROR_AUTH_TOKEN_INVALID,
  ERROR_METHOD_NOT_FOUND,
  ERR_UNKOWN,
  TOKEN_KEY_STORE,
} from '~/contants/commons';
const queryString = require('query-string');

declare var window: any;

var evt = new CustomEvent('authentication', { detail: 'Any Object Here' });
const axiosClient: AxiosInstance = axios.create({
  baseURL: process.env.BASE_URL,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    const { url } = response.config;
    if (
      response &&
      [ERROR_AUTH_TOKEN_EXPIRED, ERROR_AUTH_TOKEN_REQUIRED, ERROR_AUTH_TOKEN_INVALID].includes(
        response?.data?.error
      )
    ) {
      if (!window.expried) {
        window.expried = true;
        message.error('Vui lòng đăng nhập để tiếp tục');
      }
      window.dispatchEvent(evt);
      return;
    }
    if (response && [ERR_UNKOWN, ERROR_METHOD_NOT_FOUND].includes(response?.data?.error)) {
      message.error(`${url}: Lỗi server`);
    }

    if (response && response.data) {
      return response?.data?.data || response?.data;
    }
    return response;
  }
  // function (error) {
  //   if (error.response.status === 401) {
  //     console.log('Do something');
  //     window.dispatchEvent(evt);
  //   }
  //   return Promise.reject(error);
  // }
);

axiosClient.interceptors.request.use(function (config: any) {
  let token = window.token || localStorage.getItem(TOKEN_KEY_STORE);
  if (token) {
    config.headers.zas = token;
  }

  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }
  return config;
});

export default axiosClient;
