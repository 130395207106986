import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Switch,
  Tabs,
  message,
} from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProductSelection from '~/components/shared/ProductSelection/ProductSelection';
import { SERVER_PREFIX } from '~/contants/commons';
import InventoryAllocations from '~/modules/system-setting/pages/InventoryProductStockAvailableEdit/InventoryAllocations/InventoryAllocations';
import VariantDistributions from '~/modules/system-setting/pages/InventoryProductStockAvailableEdit/VariantDistributions/VariantDistributions';
import VariantElementDistributions from '~/modules/system-setting/pages/InventoryProductStockAvailableEdit/VariantElementDistributions/VariantElementDistributions';
import { useDetailPreOrderStockQuery } from '~/queries/inventory/useDetailPreOrderStockQuery';
import { useUpdatePreOrderStockMutation } from '~/queries/inventory/useUpdatePreOrderStockMutation';

const InventoryProductStockAvailableEdit = () => {
  const navigate = useNavigate();

  const { barcode: barcodeParams } = useParams();
  const [isQuantityMin, setIsQuantityMin] = useState(true);
  const [date, setDate] = useState(null);

  const [form] = Form.useForm<{
    barcode?: string;
    quantityMin?: number;
    quantityInit?: string;
  }>();

  const { data: preOrderStockDetail, refetch } = useDetailPreOrderStockQuery(
    barcodeParams as string,
    date
  );
  const {
    quantityInit,
    quantityMin,
    unitName,
    storeDistributions,
    variantDistributions,
    mapVariantElementDistributions,
    totalNumberOrders,
  } = preOrderStockDetail || {};

  useEffect(() => {
    refetch();
  }, [date]);

  useEffect(() => {
    if (preOrderStockDetail) {
      form.setFieldsValue({
        barcode: barcodeParams,
        quantityMin,
        quantityInit,
      });
      setIsQuantityMin(Boolean(quantityMin));
    }
  }, [barcodeParams, form, preOrderStockDetail, quantityInit]);

  const { mutate: updatePreOrderStockMutation, isSuccess, data } = useUpdatePreOrderStockMutation();

  useEffect(() => {
    if (data?.error) {
      message.error(SERVER_PREFIX + data?.error);
    } else if (isSuccess) {
      message.success('Cập Stock PreOrder thành công');
      navigate(-1);
    }
  }, [isSuccess]);

  const barcode = useWatch('barcode', form);

  const handleSave = (values: any) => {
    if (!Boolean(form.getFieldValue('barcode'))) {
      message.error('Vui lòng chọn sản phẩm');
    }
    const jsonSubmit = {
      ...values,
    };
    updatePreOrderStockMutation(jsonSubmit);
  };

  const handleSelectProduct = (value: any) => {
    form.setFieldsValue({
      barcode: value,
    });
  };

  const handleToggleQuantityMin = (value) => {
    setIsQuantityMin(value);
  };

  const handleChangeDate = (value) => {
    setDate(value ? (moment(value).format('DD-MM-YYYY') as any) : null);
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={handleSave}
      form={form}
      autoComplete="off"
    >
      <div className="mt-3 w-full">
        <div className="flex flex-col items-center">
          <Card bordered={false} className="w-9/12">
            <Space direction="vertical" className="w-full">
              <div className="flex flex-col">
                <label className="font-bold">Sản phẩm</label>
                <div className="mt-1">
                  <ProductSelection
                    disabled
                    onSelectProduct={handleSelectProduct}
                    value={barcode as string}
                  />
                  <Form.Item
                    name="barcode"
                    className="hidden"
                    rules={[{ required: true, message: 'Trường này bắt buộc!' }]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>
              <small>Đơn vị của sản phẩm sẽ tính theo base unit</small>
              <Row gutter={15}>
                <Col span={24}>
                  <div className="flex flex-col">
                    <label className="font-bold">SL khởi tạo</label>
                    <Form.Item
                      name="quantityInit"
                      rules={[{ required: true, message: 'Trường này bắt buộc!' }]}
                    >
                      <InputNumber className="w-full" addonAfter={unitName || ''} />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="flex flex-col">
                    <label className="font-bold w-full">
                      <div className="flex justify-between">
                        <span>Tồn kho tối thiểu</span>
                        <Switch
                          onChange={handleToggleQuantityMin}
                          size="small"
                          checked={isQuantityMin}
                        />
                      </div>
                    </label>
                    <Form.Item name="quantityMin">
                      <InputNumber min={0} className="w-full" disabled={!isQuantityMin} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Form.Item noStyle>
                <div className="flex justify-end">
                  <Button
                    className="mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Lưu
                  </Button>
                </div>
              </Form.Item>
            </Space>
          </Card>
          <Card bordered={false} className="w-9/12 mt-3">
            <DatePicker
              format={'DD-MM-YYYY'}
              onChange={handleChangeDate}
              style={{ width: 200, position: 'absolute', right: 10, zIndex: 2 }}
              placeholder="Chọn ngày"
            />
            <Tabs>
              <Tabs.TabPane tab={<h3 className="mb-0">Phân bổ kho</h3>} key="item-1">
                {Boolean(preOrderStockDetail?.error) && (
                  <Alert className="mb-3" message={preOrderStockDetail?.error} type="error" />
                )}
                <InventoryAllocations
                  storeDistributions={storeDistributions}
                  totalNumberOrders={totalNumberOrders}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab={<h3 className="mb-0">Phân bổ Variant</h3>} key="item-2">
                <VariantDistributions
                  variantDistributions={variantDistributions}
                  totalNumberOrders={totalNumberOrders}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={<h3 className="mb-0">Phân bổ của sản phẩm trong Combo</h3>}
                key="item-3"
              >
                <VariantElementDistributions
                  mapVariantElementDistributions={mapVariantElementDistributions}
                />
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </div>
      </div>
    </Form>
  );
};

export default InventoryProductStockAvailableEdit;
