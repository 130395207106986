import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const storeCompleteOrder = async (params?: any) => {
  return await axiosClient.post<any>(`share/storeCompleteOrder`, {
    ...params,
  });
};

export const useStoreCompleteOrder = () => {
  return useMutation({ mutationFn: (params: any) => storeCompleteOrder(params) });
};
