import { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PAGE_DEFAULT_REDIRECT } from '~/contants/commons';

import { AuthContext } from '~/contexts/AuthContext';
import { useRouterContext } from '~/contexts/RouterContext';

const PublicRoute: React.FC<any> = ({ children }) => {
  const { pathname } = useLocation();

  const { isAuth } = useContext(AuthContext);
  const location: any = useLocation();
  const { setIsPrivateRouter } = useRouterContext();

  useEffect(() => {
    setIsPrivateRouter(false);
  }, [location.pathname]);

  if (pathname === '/login' && isAuth) {
    return <Navigate to={location.state?.from?.pathname || PAGE_DEFAULT_REDIRECT} />;
  }

  return <>{children}</>;
};

export default PublicRoute;
