import axiosClient from '~/config/axiosClient';
import { useQuery } from 'react-query';
import { SHARE_SECRET_KEY } from '../orders/useOrderDetailQuery';

const getBagLabelImages = async (orderCode, bagCodes?: any) => {
  console.log(bagCodes, 'bagsCodesbagsCodes');
  const params = {
    orderCode,
    bagCodes: JSON.stringify(bagCodes),
    secretKey: SHARE_SECRET_KEY,
  };
  return await axiosClient.get<any>(`share/getBagLabelImages`, {
    params,
  });
};

export const useGetBagLabelImages = ({ orderCode, bagCodes }: any, config = {}) => {
  return useQuery(['getBagLabelImages'], () => getBagLabelImages(orderCode, bagCodes), {
    ...config,
  });
};
