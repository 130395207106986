import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import GlobalStyle from '~/components/GlobalStyle/GlobalStyle';
import IProgess from '~/components/nprogress/NProgess';
import reportWebVitals from '~/reportWebVitals';
import App from '~/App';

import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalProvider from '~/contexts/GlobalContext';
import SidebarProvider from '~/contexts/NavbarContext';
import AuthContextProvider from '~/contexts/AuthContext';
import RouterContextProvider from '~/contexts/RouterContext';

const container = document.getElementById('root')!;
const root = createRoot(container);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      staleTime: 0,
      retry: false,
      refetchOnWindowFocus: true,
    },
  },
});
root.render(
  //<React.StrictMode>
  <IProgess>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <RouterContextProvider>
          <AuthContextProvider>
            <GlobalStyle>
              <GlobalProvider>
                <SidebarProvider>
                  <App />
                </SidebarProvider>
              </GlobalProvider>
            </GlobalStyle>
          </AuthContextProvider>
        </RouterContextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </IProgess>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
