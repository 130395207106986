import { Button, Image, Popover, Space } from 'antd';
import React, { useState } from 'react';
import CurrencyFormat from '~/components/commons/CurrencyFormat';
import { fallbackImage } from '~/contants/commons';
import InputAddCart from '~/components/shared/_molecules/InputAddCart';

type Props = {
  onAdd?: ({ amount }: { amount?: number }) => void;
} & Product;

export const ContentProductPopOver = ({
  image,
  name,
  sellPrice,
  originPrice,
  stockAvailable,
  unit,
}: Partial<Product>) => {
  const discount = Math.floor(
    ((Number(originPrice) - Number(sellPrice)) / Number(originPrice)) * 100
  );
  return (
    <div className="flex gap-4 justify-between" style={{ maxWidth: '450px' }}>
      <Image
        wrapperStyle={{ maxWidth: '120px' }}
        fallback={fallbackImage}
        className="mt-3"
        preview={false}
        src={image}
      />
      <Space direction="vertical">
        <h3 className="mb-0">{name}</h3>
        <Space>
          <span>Kho khả dụng:</span>
          <span className="">
            {Number(stockAvailable) || 0} {unit}
          </span>
        </Space>
        <div className="flex items-center gap-2">
          <Space>
            <>
              <span>Đơn giá:</span>
              {sellPrice === originPrice && (
                <span>
                  <CurrencyFormat number={originPrice || 0} />
                </span>
              )}
              {sellPrice !== originPrice && (
                <>
                  <span
                    style={{
                      textDecoration: 'line-through',
                      marginRight: '4px',
                    }}
                  >
                    <CurrencyFormat number={originPrice || 0} />
                  </span>
                  <span>
                    <CurrencyFormat number={sellPrice || 0} />
                  </span>
                </>
              )}
            </>
          </Space>
        </div>
        <Space>
          <span>Giảm giá:</span>
          <span
            style={{
              marginLeft: '2px',
            }}
          >
            - {discount || 0}%
          </span>
        </Space>
      </Space>
    </div>
  );
};

const ProductItem: React.FC<Props> = ({
  barcode,
  image,
  name,
  originPrice,
  sellPrice,
  stockAvailable,
  unit,
  onAdd,
}) => {
  const [amount, setAmount] = useState(1);

  const handleMinus = () => {
    setAmount((currentValue: number) => {
      if (currentValue > 1) {
        return currentValue - 1;
      }
      return currentValue;
    });
  };

  const handlePlus = () => {
    setAmount((currentValue: number) => currentValue + 1);
  };

  return (
    <Space
      size={5}
      direction="vertical"
      className="w-100 bg-white px-1 py-3 flex flex-col justify-between "
    >
      <Popover
        trigger="hover"
        placement="right"
        content={
          <ContentProductPopOver
            {...{ image, name, originPrice, sellPrice, stockAvailable, unit }}
          />
        }
      >
        <Image
          fallback={fallbackImage}
          preview={false}
          width={'100%'}
          height={200}
          src={image || 'error'}
          style={{ objectFit: 'contain' }}
        />
      </Popover>
      <div className="px-1  ">
        {sellPrice !== originPrice && (
          <h3 className="mb-0 text-orange-500">
            <CurrencyFormat number={sellPrice} />
          </h3>
        )}
        <span className={`${sellPrice !== originPrice && 'line-through text-gray-500'}`}>
          <CurrencyFormat number={originPrice} />
        </span>
        <h4
          className="mb-0"
          style={{
            maxWidth: '100%',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minHeight: '45px',
          }}
        >
          {name}
        </h4>
        <div className="mt-3 flex justify-between items-center ">
          <InputAddCart
            onChangeAmount={(value: number) => setAmount(Number(value))}
            onMinus={handleMinus}
            onPlus={handlePlus}
            amount={amount}
          />
          {onAdd && (
            <Button
              onClick={() => {
                setAmount(1);
                onAdd?.({ amount });
              }}
              size="small"
              className="px-2 rounded bg-indigo-600 text-center h-full"
            >
              <span style={{ fontSize: '12px', color: '#ffffff' }}>Thêm</span>
            </Button>
          )}
        </div>
      </div>
    </Space>
  );
};

export default React.memo(ProductItem);
