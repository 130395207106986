import React from 'react';

export const formatterNumber = (number: number = 0) =>
  new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(
    parseFloat(number.toFixed(3))
  );

const CurrencyFormat: React.FC<{
  number: string | number | null;
}> = ({ number = 0 }): React.ReactElement => {
  const formatter = formatterNumber(number as any);
  return <span>{formatter?.replaceAll('.', ',')}</span>;
};

export default CurrencyFormat;
