import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, DrawerProps } from 'antd';
import React from 'react';
import ProductsForm from '~/components/shared/ProductsForm';
import { useGlobalContext } from '~/contexts/GlobalContext';

import { useCouponDrawerContext } from '~/modules/operate-order/pages/CouponDetail/contexts/CouponDrawerContext';
import { useProductCouponDetailContext } from '~/modules/operate-order/pages/CouponDetail/contexts/ProductCouponDetailContext';
import { findConfigById } from '~/ultils/config-utils';
import { useCouponDetailContext } from '../../../contexts/CouponDetailContext';
import './custom.scss';

type Props = {
  onClose?: () => void;
  visible?: boolean;
} & DrawerProps;

const ProductsFormDrawer: React.FC<Props> = ({ onClose, ...restProps }) => {
  const { isShowProductForm, setShowProductForm } = useCouponDrawerContext();


  const { delivery } = useCouponDetailContext();
  const {
    productItems: orderProducts,
    onAddProduct,
    onRemoveProduct,
    decreaseQuantity,
    increaseQuantity,
    onChangeQuantity,
  } = useProductCouponDetailContext();

  return (
    <Drawer
      title=""
      placement={'bottom'}
      height="97%"
      onClose={() => setShowProductForm?.(false)}
      visible={isShowProductForm}
      key={'shiping'}
      headerStyle={{ padding: '0' }}
      bodyStyle={{ padding: '0px 15px' }}
      className="product-drawer"
      closeIcon={
        <Button
          size="small"
          icon={<CloseOutlined />}
          shape="circle"
          className="close-product"
        ></Button>
      }
      {...restProps}
    >
      {isShowProductForm && (
        <ProductsForm
          products={orderProducts}
          storeCode={String(delivery?.storeCode)}
          onChangeAmount={onChangeQuantity}
          onAddProduct={onAddProduct}
          onRemoveProduct={onRemoveProduct}
          onDecreaseQuantity={decreaseQuantity}
          onIncreaseQuantity={increaseQuantity}
        />
      )}
    </Drawer>
  );
};

export default React.memo(ProductsFormDrawer);
