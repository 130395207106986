export declare var messageHandler: any;

export const onBackWebView = () => {
  if (messageHandler) {
    messageHandler.postMessage(
      JSON.stringify({
        action: 'CLOSE_WEBVIEW',
      })
    );
  }
};
