import { Alert, Button, Col, Image, Row, message } from 'antd';
import moment from 'moment-timezone';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import Loading from '~/components/loading';
import ProductTitle from '~/components/shared/_molecules/ProductTitle';
import { SERVER_PREFIX, fallbackImage } from '~/contants/commons';
import { StatusOfOrder } from '~/contants/order';
import { useStoreBookShipper } from '~/queries/commons/useStoreBookShipper';
import { useStoreShippingOrder } from '~/queries/commons/useStoreShippingOrder';
import { useCompleteOrderMutation } from '~/queries/orders/useCompleteOrder';
import { useOrderDetailQuery } from '~/queries/orders/useOrderDetailQuery';
import { BaseUtils } from '~/ultils/base-utils';
import AhamoveBookShippingConfirmation from './AhamoveBookShippingConfirmation';
import './styles.scss';

const DetailOrderInfo = ({ header = {} }: any) => {
  const deliveryTimeRange = useMemo(() => {
    const startTime = header?.deliveryTimeRange?.[0];
    const endTime = header?.deliveryTimeRange?.[1];

    if (!startTime || !endTime) return '--';
    if (moment(startTime).valueOf() === moment(endTime).valueOf())
      return moment(startTime).format('HH:mm') + ' - ';
    return moment(startTime).format('HH:mm') + ' - ' + moment(endTime).format('HH:mm DD/MM/YYYY');
  }, [header?.deliveryTimeRange]);

  return (
    <div className="bg-white px-3 gap-y-3 py-5">
      <Row gutter={[10, 15]}>
        <Col span={14}>
          <div className="flex">
            <span className="text-gray-500 font-medium" style={{ width: 90, display: 'block' }}>
              Trạng thái
            </span>
            <span>{header?.statusName}</span>
          </div>
        </Col>
        <Col span={10}>
          <div className="flex">
            <span className="text-gray-500 font-medium" style={{ width: 50, display: 'block' }}>
              COD
            </span>
            <span className="font-medium">{BaseUtils.formatNumber(header?.codAmount)}đ</span>
          </div>
        </Col>
        <Col span={14}>
          <div className="flex">
            <span className="text-gray-500 font-medium" style={{ width: 90, display: 'block' }}>
              Khách hàng
            </span>
            <span className="font-medium">{header?.customer?.name}</span>
          </div>
        </Col>
        <Col span={10}>
          <a href={`tel:${header?.customer?.phone}`} className="flex items-center">
            <span className="font-medium">{header?.customer?.phone}</span>
          </a>
        </Col>
        <Col span={24}>
          <div className="flex">
            <span className="text-gray-500 font-medium" style={{ width: 90, display: 'block' }}>
              Giờ giao
            </span>
            <span className="font-medium">{deliveryTimeRange}</span>
          </div>
        </Col>
        <Col span={24}>
          <div
            className="text-gray-500 font-medium float-left"
            style={{ width: 90, height: '100%', overflow: 'hidden', display: 'block' }}
          >
            Địa chỉ
          </div>
          <div>
            <span className="font-medium w-100">{header?.deliveryAddress?.fullAddress}</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const Products = ({ products }) => {
  return (
    <div className="flex flex-col gap-y-5">
      {products?.map((product, index) => {
        const { barcode, image, quantity, unit } = product;
        return (
          <div
            key={index}
            className="py-5"
            style={index !== products?.length - 1 ? { borderBottom: '1px solid #dfdfdf' } : {}}
          >
            <div className="px-3 flex justify-between">
              <div className="flex gap-3 ">
                <Image width={50} src={image || 'error'} fallback={fallbackImage} />
                <div>
                  <div>{barcode}</div>
                  <ProductTitle {...product}></ProductTitle>
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex gap-1">
                  <span> {quantity}</span>
                  <span>{unit}</span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const OrderDetail = () => {
  const { orderCode } = useParams();
  const queryClient = useQueryClient();

  const [visibleHeavyBookShipping, setVisibleHeavyBookShipping] = useState(false);

  const { data: orderDetail, isLoading: isLoadingOrder } = useOrderDetailQuery(orderCode, {
    enabled: true,
  });

  const reloadOrderDetail = () => {
    queryClient.invalidateQueries({ queryKey: ['orderDetail'] });
  };

  const {
    mutate: onShippingOrder,
    isSuccess: isSuccessShippingOrder,
    data: completeShippingRes,
    isLoading: loadingShipping,
  } = useStoreShippingOrder();

  useEffect(() => {
    if (completeShippingRes?.error) {
      message.error(SERVER_PREFIX + completeShippingRes?.error);
    } else if (isSuccessShippingOrder) {
      reloadOrderDetail();
      message.success('Bắt đầu giao hàng');
    }
  }, [isSuccessShippingOrder]);

  const handleShippingOrder = () => {
    onShippingOrder({
      orderCode,
    });
  };

  const {
    mutate: onCompleteOrder,
    isSuccess: isSuccessCompleteOrder,
    data: completeOrderRes,
    isLoading: loadingCompleteOrder,
  } = useCompleteOrderMutation();

  useEffect(() => {
    if (completeOrderRes?.error) {
      message.error(SERVER_PREFIX + completeOrderRes?.error);
    } else if (isSuccessCompleteOrder) {
      message.success('Hoàn tất đơn hàng thành công');
      reloadOrderDetail();
    }
  }, [isSuccessCompleteOrder]);

  const handleCompleteOrder = () => {
    onCompleteOrder({
      orderCode,
    });
  };

  const {
    mutate: storeBookShipper,
    isSuccess: isSuccessBookShipperAhamove,
    isLoading: loadingBookShipperAhamove,
    data: dataBookShipperAhamove,
  } = useStoreBookShipper();

  const handleOpenConfirmBookShipperAhamove = useCallback(() => {
    setVisibleHeavyBookShipping(true);
  }, []);

  useEffect(() => {
    if (dataBookShipperAhamove?.error) {
      message.error(SERVER_PREFIX + dataBookShipperAhamove?.error);
    } else if (isSuccessBookShipperAhamove) {
      setVisibleHeavyBookShipping(false);
      message.success('Book tài xế thành công');
      queryClient.invalidateQueries(['orderDetail']);
    }
  }, [isSuccessBookShipperAhamove]);

  const { delivery, header } = orderDetail || {};
  const { status } = header || {};

  const { productItems: products } = delivery || {};

  const showSubmitButton = [StatusOfOrder.SHIPPING, StatusOfOrder.STORE_PACKED].includes(status);

  const handleCloseHeavyBookShipping = () => {
    setVisibleHeavyBookShipping(false);
  };

  const handleSubmitHeavyBookingShipping = ({ packageSize, scheduleType }) => {
    storeBookShipper({
      orderCode: orderCode,
      packageSize,
      scheduleType,
    });
  };

  if (isLoadingOrder) return <Loading />;

  if (orderDetail?.error) {
    return (
      <div className="p-3">
        <Alert type="error" description={orderDetail?.error} />
      </div>
    );
  }

  return (
    <>
      <div className="order-bags">
        <div className="header flex gap-2 items-center justify-between px-4">
          <div className="__left flex gap-2 items-center cursor-pointer">
            <span className="font-medium ml-5" style={{ fontSize: 17 }}>
              {orderCode} - Home delivery
            </span>
          </div>
        </div>
        <div className="content-scroll pb-4">
          <DetailOrderInfo header={header} />
          <div className="bg-white gap-y-3 mt-4">
            <Products products={products} />
          </div>
        </div>
        {showSubmitButton && (
          <div className="footer flex justify-between">
            {status === StatusOfOrder.SHIPPING && (
              <Button
                onClick={handleCompleteOrder}
                loading={loadingCompleteOrder}
                style={{ flex: 1, borderRadius: 0 }}
                className="bg-orange-500 h-full"
                type="text"
              >
                <span className="text-white text-lg">GIAO HÀNG THÀNH CÔNG</span>
              </Button>
            )}
            {status !== StatusOfOrder.SHIPPING && (
              <>
                <Button
                  onClick={handleOpenConfirmBookShipperAhamove}
                  loading={loadingBookShipperAhamove}
                  style={{ flex: 1, borderRadius: 0 }}
                  className="bg-orange-500 h-full"
                  type="text"
                >
                  <span className="text-white text-lg">BOOK AHAMOVE</span>
                </Button>
                <Button
                  onClick={handleShippingOrder}
                  loading={loadingShipping}
                  style={{ flex: 1, borderRadius: 0 }}
                  className="bg-orange-400 h-full"
                  type="text"
                >
                  <span className="text-white text-lg">STORE GIAO HÀNG</span>
                </Button>
              </>
            )}
          </div>
        )}
        <AhamoveBookShippingConfirmation
          onClose={handleCloseHeavyBookShipping}
          isOpen={visibleHeavyBookShipping}
          onOk={handleSubmitHeavyBookingShipping}
        />
      </div>
    </>
  );
};

export default OrderDetail;
