import { useParams } from 'react-router-dom';
import TableAntCustom from '~/components/antd/ITable/TableAntCustom';

const columns = [
  {
    title: '#',
    dataIndex: 'storeCode',
    key: 'storeCode',
  },
  {
    title: 'Tên',
    dataIndex: 'storeName',
    key: 'storeName',
  },
  {
    title: 'SL đơn đã đặt',
    dataIndex: 'numberOrders',
    key: 'numberOrders',
  },
  {
    title: 'SL SP đã đặt',
    dataIndex: 'quantityOrdered',
    key: 'quantityOrdered',
  },
  {
    title: 'Tồn kho thực tế',
    dataIndex: 'quantityAvailable',
    key: 'quantityAvailable',
  },
];

function InventoryAllocations({ storeDistributions, totalNumberOrders }: any) {

  return (
    <div>
      <TableAntCustom
        pagination={false}
        loading={false}
        columns={columns}
        dataSource={storeDistributions}
      />
    </div>
  );
}

export default InventoryAllocations;
