import { Alert } from 'antd';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Loading from '~/components/loading';
import { useGetOrderBags } from '~/queries/commons/useGetOrderBags';

const WIDTH = 560;
const HEIGHT = 400;

const LabelOrderBags = () => {
  const [searchParams] = useSearchParams();
  const [isReadyScreenshot, setIsReadyScreenshot] = useState(false);

  const orderCode: any = searchParams.get('orderCode');
  const bagCode: any = searchParams.get('bagCode');

  const { data: orderBags, isLoading } = useGetOrderBags({ orderCode, bagCode });

  useEffect(() => {
    if (orderBags) {
      setTimeout(() => {
        setIsReadyScreenshot(true);
      }, 200);
    }
  }, [orderBags]);

  if (isLoading) return <Loading />;

  if (orderBags?.error) {
    return <Alert className="m-auto mt-4 mx-3" type="error" message={orderBags.error} />;
  }

  if (!orderBags?.length)
    return <Alert className="m-auto mt-4 mx-3" type="error" message={'Không có label nào'} />;

  return (
    <div
      className="flex justify-center"
      style={{ background: 'transparent' }}
      id={isReadyScreenshot ? 'screenshot-ready' : ''}
    >
      <div className="flex flex-col gap-y-5 my-5" style={{ width: WIDTH }}>
        {(orderBags || [])?.map(
          ({
            code,
            receiverName,
            name,
            typeName,
            receiverPhone,
            deliveryAddress,
            deliveryTimeRange,
          }) => {
            return (
              <div
                key={code}
                className={`flex flex-col ${isReadyScreenshot ? 'screenshot-ready' : ''}`}
                style={{ border: '1px dotted #dfdfdf', height: HEIGHT }}
              >
                <div className="flex justify-between p-3" style={{ borderBottom: '2px dotted' }}>
                  <div className="flex gap-2 uppercase">
                    <span className="font-bold uppercase text-3xl">{typeName} -</span>
                    <span className="font-bold text-3xl">{name}</span>
                  </div>
                </div>
                <div className="flex">
                  <div className="p-3 flex" style={{ borderRight: '2px dotted' }}>
                    <div className="flex flex-col justify-center gap-y-1">
                      <QRCode size={200} value={code} bgColor="transparent" />
                      <div className="flex flex-col items-center">
                        <span className="font-bold text-xl whitespace-no-wrap">{code}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col" style={{ flexGrow: 1 }}>
                    <div className="flex flex-col gap-y-4 px-3 py-1">
                      <div>
                        <div className="flex flex-col">
                          <span className="font-bold mr-4 text-2xl">KHÁCH HÀNG</span>
                          <span className="font-bold flex flex-col text-3xl">
                            <span>{receiverName}</span>
                            <span>{receiverPhone}</span>
                          </span>
                        </div>
                        <div className="flex items-center gap-3 text-xl">
                          <span className="font-bold whitespace-no-wrap">NGÀY GIAO</span>
                          <span className="font-bold whitespace-no-wrap">{deliveryTimeRange}</span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <span className="font-bold text-xl">{`${deliveryAddress}`}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default LabelOrderBags;
