import axiosClient from '~/config/axiosClient';
import { useQuery } from 'react-query';
import { SHARE_SECRET_KEY } from '../orders/useOrderDetailQuery';

const getOrderBags = async (orderCode: any, bagCode: any) => {
  const params = {
    orderCode,
    bagCode,
    secretKey: SHARE_SECRET_KEY,
  };
  return await axiosClient.get<OrderListTypes>(`share/getOrderBags`, {
    params,
  });
};

export const useGetOrderBags = ({ orderCode, bagCode }: any, config = {}) => {
  return useQuery(['getOrderBags'], () => getOrderBags(orderCode, bagCode), { ...config });
};
