import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const updateEmployee = async (params) => {
  return await axiosClient.post<any>('employee/updateProfile', { data: { ...params } });
};

export const useUpdateEmployee = () => {
  return useMutation({
    mutationFn: (params) => updateEmployee(params),
  });
};
