import { useLocalStorage } from 'react-use';

export const useUserInfoReset = () => {
  const [, , removeToken] = useLocalStorage('token', null);
  const [userInfo, , removeUserInfo] = useLocalStorage<AuthUser>('userInfo');

  const resetUser = () => {
    removeToken();
    removeUserInfo();
  };

  return {
    ...userInfo,
    resetUser,
  };
};
