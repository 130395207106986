import { RefundMethodOrder, StatusOfOrder } from '~/contants/order';

const canEditOrder = (status?: any) =>
  [
    StatusOfOrder.NEW,
    StatusOfOrder.ASSIGNED,
    StatusOfOrder.CONFIRMED,
    StatusOfOrder.STORE_PACKED,
    StatusOfOrder.STORE_PICKING,
    StatusOfOrder.SHIPPING,
  ].includes(status);

const getRefundStatus = (order: any) => {
  const isMethodRefundOneLife = order?.refund?.method === RefundMethodOrder.ONE_LIFE;
  if (order.refund) {
    return isMethodRefundOneLife ? 'Đã hoàn trả' : 'Đã yêu cầu hoàn trả';
  }
  if (order.incurredPayment) {
    return 'Mới';
  }
  return null;
};

const calcTotalPriceOrder = (products) => {
  return products?.reduce(
    (previousValue: any, currentValue: any) =>
      Math.round(
        previousValue + Number(currentValue.sellPrice || 0) * Number(currentValue.quantity || 0)
      ),
    0
  );
};

export { canEditOrder, getRefundStatus, calcTotalPriceOrder };
