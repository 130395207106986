import React from 'react';
import DeliveryOrderBagsContextProvider from './context';
import DeiveryOrderBags from './DeiveryOrderBags';

const OrderBagsWrapper = () => {
  return (
    <DeliveryOrderBagsContextProvider>
      <DeiveryOrderBags />
    </DeliveryOrderBagsContextProvider>
  );
};

export default OrderBagsWrapper;
