import { LoadingOutlined } from '@ant-design/icons';
import { Col, Popover, Row, Spin } from 'antd';
import React, { PropsWithChildren, useEffect } from 'react';
import { useGetProfileCustomer } from '~/queries/customer/useGetProfileCustomer';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type Props = {
  phoneNumber?: string | number;
} & PropsWithChildren;

const RatingCustomer = ({ phoneNumber }: any) => {
  const {
    data: profile,
    isFetching,
    refetch: fetchRatingCustomer,
  } = useGetProfileCustomer(phoneNumber, { enabled: false });

  const { rating: ratingCustomer } = profile || {};

  useEffect(() => {
    if (phoneNumber) {
      fetchRatingCustomer();
    }
  }, [phoneNumber]);

  return (
    <>
      {isFetching ? (
        <div className="p-3 text-center">
          <Spin indicator={antIcon} size="small" tip="Đang tải..."></Spin>
        </div>
      ) : (
        <Row gutter={5} style={{ width: 250 }}>
          <Col span={12}>
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <span className="mr-2 font-medium">Trust score:</span>
                <span className="mr-2">{ratingCustomer?.trust || 0}</span>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <span className="mr-2 font-medium ">Churn rate:</span>
                <span className="mr-2">{ratingCustomer?.churn || 0}</span>
              </div>
            </div>
          </Col>
          <Col span={12} className="hidden">
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <span className="mr-2 font-medium ">Buying:</span>
                <span className="mr-2">{ratingCustomer?.buying || 0}</span>
              </div>
            </div>
          </Col>
          <Col span={12} className="hidden">
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <span className="mr-2 font-medium ">Add to cart:</span>
                <span className="mr-2">{ratingCustomer?.addToCard || 0}</span>
              </div>
            </div>
          </Col>
          <Col span={12} className="hidden">
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <span className="mr-2 font-medium ">High LTV:</span>
                <span className="mr-2">{ratingCustomer?.highLTV || 0}</span>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

const CustomerPopOver: React.FC<Props> = ({ children, statusRefund, phoneNumber }: any) => {
  return (
    <Popover
      content={<RatingCustomer phoneNumber={phoneNumber} statusRefund={statusRefund} />}
      title={null}
    >
      {children}
    </Popover>
  );
};

export default CustomerPopOver;
