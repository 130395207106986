import { useQuery } from 'react-query';
import axiosClient from '~/config/axiosClient';

export interface FilterSearchCustomer {
  keyword?: string;
  sortType?: 1 | -1;
  pageIndex?: number;
  limit?: number;
  sortBy?: string;
  createdTimeRange?: string;
}

const searchCustomers = async (filter: FilterSearchCustomer) => {
  const params = {
    filter: JSON.stringify({ ...filter }),
  };
  return await axiosClient.get('/customer/searchCustomers', { params });
};

export const useSearchCustomers = (filter: FilterSearchCustomer, config = { enabled: true }) => {
  return useQuery(['searchCustomers'], () => searchCustomers(filter), {
    ...config,
  });
};
