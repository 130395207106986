import moment from 'moment-timezone';
import { useQuery } from 'react-query';
import axiosClient from '~/config/axiosClient';

export type GetSaleStockRequest = {
  metric?: string;
  categoryCode?: string;
  barcode?: string;
  storeCode?: string;
  startTime?: number;
  endTime?: number;
  numberBuckets?: number;
};

const getStockStats = async (filter: GetSaleStockRequest) => {
  const params = {
    filter: JSON.stringify({
      ...filter,
      startTime: moment(filter.startTime).startOf('day').valueOf(),
      endTime: moment(filter.endTime).endOf('day').valueOf(),
    }),
  };
  return await axiosClient.get('stat/getStockStats', { params });
};

export const useGeStockStats = (filter: GetSaleStockRequest, config = { enabled: true }) => {
  return useQuery(['getStockStats'], () => getStockStats(filter), {
    ...config,
  });
};
