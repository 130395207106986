import { MenuProps } from 'antd';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PAGE_DEFAULT_REDIRECT } from '~/contants/commons';
import useSidebar from '~/hooks/useSidebar';
import View from './View';
import { _nav } from './_nav';

const Sidebar = () => {
  const navs = useMemo(() => _nav || [], []);

  const ROOT_MENU: string[] | undefined = navs && navs.map((nav: any) => nav.key);
  const NAV_NOT_CHILD = navs && navs.filter((nav: any) => !nav.children).map((nav: any) => nav.key);

  const { isOpenSidebar } = useSidebar();
  const location = useLocation();

  const [openKeys, setOpenKeys] = useState<string[]>(['']);

  useLayoutEffect(() => {
    const parentPath = location.pathname.split('/')[1] || PAGE_DEFAULT_REDIRECT;
    if (!isOpenSidebar) {
      setOpenKeys([parentPath]);
    }
  }, [isOpenSidebar, location]);

  const onOpenChange: MenuProps['onOpenChange'] = useCallback(
    (keys: any) => {
      const latestOpenKey = keys.find((key: string) => openKeys.indexOf(key) === -1);
      if (ROOT_MENU && ROOT_MENU.indexOf(latestOpenKey!) === -1) {
        setOpenKeys(keys);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    },
    [openKeys]
  );

  const handleMenuClick = useCallback((obj: any) => {
    if (NAV_NOT_CHILD?.includes(obj.key)) {
      setOpenKeys([]);
    }
  }, []);

  const filterNav = useCallback((array: any) => {
    const getNodes = (result: any, object: any) => {
      if (Boolean(JSON.parse(object?.permissions))) {
        const tempObject = { ...object };
        let newChildren: any = [];
        if (object.children) {
          delete tempObject.children;

          newChildren = object.children.map((child: any) => {
            try {
              if (Boolean(JSON.parse(child?.permissions))) {
                return child;
              }
              return null;
            } catch {
              return null;
            }
          });
        }
        newChildren = newChildren.filter((child: any) => child !== undefined);
        if (newChildren.length > 0) {
          result.push({ ...tempObject, children: [...newChildren] });
        } else {
          if (typeof object.label !== 'string') {
            result.push(object);
          }
        }
        return result;
      }
      if (Array.isArray(object.children)) {
        const nodes = object.children.reduce(getNodes, []);
        if (nodes.length) result.push({ ...object, nodes });
      }
      return result;
    };

    return array.reduce(getNodes, []);
  }, []);

  const composeProps = {
    isOpenSidebar,
    handleMenuClick,
    openKeys,
    onOpenChange,

    navData: navs,
    filter: filterNav,
  };

  return <View {...composeProps} />;
};

export default Sidebar;
