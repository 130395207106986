import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';
import { OrderDelivery } from '~/types/orderDetailType';

const updateOrderDelivery = async (orderDelivery?: OrderDelivery) => {
  return await axiosClient.post<OrderListTypes>(`/order/updateOrderDelivery`, {
    ...orderDelivery,
  });
};

export const useOrderDeliveryMutation = () => {
  return useMutation({
    mutationFn: (orderDelivery: OrderDelivery) => updateOrderDelivery(orderDelivery),
  });
};
