import { notification } from 'antd';
import 'antd/dist/antd.variable.min.css';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { useContext, useEffect, useRef } from 'react';
import { AuthContext } from '~/contexts/AuthContext';
import { useGetNotificationQuery } from '~/queries/commons/useGetNotificationQuery';

import { useGlobalContext } from '~/contexts/GlobalContext';

const INTERVAL_TIME = 1000;

const NotificationTask = () => {
  const queryClient = useQueryClient();
  const { isAuth } = useContext(AuthContext);

  const intervalRef: any = useRef(null);
  const { settings } = useGlobalContext();

  const location = useLocation();

  const channelId = localStorage.getItem('channelId');

  const { hostname } = window.location;

  const config = { enabled: false };
  
  const { data, refetch: refetchNoti } = useGetNotificationQuery({
    channelId,
    topic: location?.pathname === '/' ? '/home' : location?.pathname,
    config,
  });

  useEffect(() => {
    if(!Boolean(isAuth) || hostname.startsWith('localhost')){
      if(intervalRef.current) clearInterval(intervalRef.current);
      return;
    }

    if(intervalRef.current) clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      refetchNoti();
    }, INTERVAL_TIME);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [hostname, isAuth, location.pathname, refetchNoti]);

  useEffect(() => {
    const { pathname } = location;

    if (!data?.error && data?.length) {
      data?.map((noti: NotificationOrder) => {
        if (settings.enableNotice) {
          notification.info({
            message: 'Thông báo mới',
            description: noti?.content,
            placement: 'bottomRight',
          });
        }
        if (pathname?.includes(String(noti?.extraData?.orderCode))) {
          queryClient.invalidateQueries({ queryKey: ['orderDetail'] });
        }
        if (pathname?.includes('list-order')) {
          queryClient.invalidateQueries({ queryKey: ['searchOrders'] });
        }
        if (pathname === '/') {
          queryClient.invalidateQueries({ queryKey: ['getOrderStatusCounters'] });
        }
      });
    }
  }, [data, location, queryClient, settings]);

  return <></>;
};

export default NotificationTask;
