import { Badge, Layout, Menu, Tag } from 'antd';

import classNames from 'classnames/bind';
import { memo } from 'react';
import styles from './Sidebar.module.scss';
import './Sidebar.scss';

import { Link, useLocation } from 'react-router-dom';
import { LogoIcon, LogoIconSplit } from '~/components/Icons';
import { PAGE_DEFAULT_REDIRECT } from '~/contants/commons';

import { createBreakpoint } from 'react-use';

const useBreakpoint = createBreakpoint();

const cx = classNames.bind(styles);

const { Sider } = Layout;

const View: React.FC<any> = ({
  isOpenSidebar,
  handleMenuClick,
  openKeys,
  onOpenChange,

  filter,
  navData,
}): React.ReactElement => {
  const location = useLocation();
  const breakpoint = useBreakpoint();

  return (
    <>
      <Sider
        className={cx('hidden-scroll', 'sidebar')}
        width={breakpoint !== 'tablet' ? 230 : 0}
        collapsed={isOpenSidebar}
        style={{
          backgroundColor: '#ffffff',
          height: '100vh',
          overflow: 'auto',
          position: 'sticky',
          left: '0',
          top: '0',
          borderRight: '1px solid rgba(0,0,0,.06)',
        }}
      >
        <Link to={'/'} className={cx('logo')}>
          {!isOpenSidebar ? (
            <div className="flex items-start">
              <LogoIcon />
            </div>
          ) : (
            <LogoIconSplit />
          )}
        </Link>

        <Menu
          onClick={handleMenuClick}
          mode="inline"
          selectedKeys={[location.pathname]}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          defaultSelectedKeys={[PAGE_DEFAULT_REDIRECT]}
          defaultOpenKeys={[PAGE_DEFAULT_REDIRECT]}
          style={{ borderRight: 0 }}
          items={filter(navData)}
        />
      </Sider>
    </>
  );
};
export default memo(View);
