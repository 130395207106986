import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const updateOrderBags = async (params?: any) => {
  return await axiosClient.post<any>(`share/updateOrderBags`, {
    ...params,
  });
};

export const useUpdateOrderBags = (config = {}) => {
  return useMutation({ mutationFn: (params: any) => updateOrderBags(params), ...config });
};
