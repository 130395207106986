import axiosClient from '~/config/axiosClient';
import { useQuery } from 'react-query';

const getConfigs = async (params?: any) => {
  return await axiosClient.get<OrderListTypes>(`config/getAll`, {
    params,
  });
};

export const useConfigsQuery = (version?: any, config = {}) => {
  return useQuery(['configs', version], () => getConfigs({ version }), { ...config });
};
