import { useQuery } from 'react-query';
import axiosClient from '~/config/axiosClient';

const getOrderRefundStatusCounters = async () => {
  return await axiosClient.get('order/getOrderRefundStatusCounters');
};

export const useOrderRefundStatusCountersQuery = (config = { enabled: true }) => {
  return useQuery(['getOrderRefundStatusCounters'], () => getOrderRefundStatusCounters(), {
    ...config,
  });
};
