import { useState } from 'react';

export type PopupCouponType = {
  isShowWarehouse?: boolean;
  setShowWarehouse?: (status: boolean) => void;

  isShowProductForm?: boolean;
  setShowProductForm?: (status: boolean) => void;
};

const useCouponDrawer = () => {
  const [isShowProductForm, setShowProductForm] = useState<boolean>(false);

  const states = {
    isShowProductForm,
  };

  const actions = {
    setShowProductForm,
  };

  return {
    states,
    actions,
  };
};

export default useCouponDrawer;
