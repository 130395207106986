import React from 'react';
import { Table, TableProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const TableAntCustom: React.FC<TableProps<any> & { iKey?: string; loading?: boolean }> = ({
  loading,
  iKey = 'id',
  ...restProps
}) => {
  const tableLoading = {
    spinning: loading,
    indicator: antIcon,
  };
  return <Table rowKey={(record) => record[iKey]} loading={tableLoading} {...restProps} />;
};

export default TableAntCustom;
