import { LoadingOutlined } from '@ant-design/icons';
import { Col, DatePicker, Divider, Empty, Row, Select, Tree, message } from 'antd';

import ApexCharts from 'apexcharts';
import moment from 'moment-timezone';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SCard, StoreSelection } from '~/components/commons';
import { useGlobalContext } from '~/contexts/GlobalContext';
import { useGetCategoryTree } from '~/queries/category/useGetCategoryTree';
import { useGeStockStats } from '~/queries/stat/useGeStockStats';
import { BaseUtils } from '~/ultils/base-utils';
import { dataCharts } from './constants';
import ProductSelection from '~/components/shared/ProductSelection';

const { RangePicker } = DatePicker;
const { Option } = Select;

const NUMBER_BUCKETS_OPTIONS = [
  {
    id: 50,
  },
  {
    id: 100,
  },
  {
    id: 150,
  },
  {
    id: 200,
  },
];

const Stock = () => {
  const { stores, stockStatMetrics }: any = useGlobalContext();
  const refChart: any = useRef();

  const [storeSelected, setStoreSelected] = useState();
  const [rangeDate, setRangeDate] = useState<any>([moment().subtract(7, 'days'), moment()]);
  const [categoryCode, setCategory] = useState<any>();
  const [barcode, setBarCode] = useState<any>(null);
  const [metric, setMertric] = useState();
  const [numberBuckets, setNumberBuckets] = useState(50);

  const { data: categories, isLoding: isLoadingCategories } = useGetCategoryTree();
  const {
    data: saleStockStats,
    refetch: getStockStats,
    isLoding: isLoadingSaleStockStats,
  } = useGeStockStats(
    {
      metric,
      barcode,
      categoryCode,
      storeCode: storeSelected,
      startTime: rangeDate?.[0]
        ? moment(rangeDate?.[0]).valueOf()
        : moment().subtract(7, 'days').valueOf(),
      endTime: rangeDate?.[1] ? moment(rangeDate?.[1]).valueOf() : moment().valueOf(),
      numberBuckets: numberBuckets,
    },
    { enabled: false }
  );

  useEffect(() => {
    if (!barcode && !categoryCode) return;
    getStockStats();
  }, [storeSelected, rangeDate, categoryCode, barcode, metric, numberBuckets]);

  useEffect(() => {
    if (categories?.error) {
      message.error(categories?.error);
    }
  }, [categories?.error]);

  const { subs } = categories || {};

  const isEmptyData = !saleStockStats || !barcode;

  const transformData = useMemo(() => {
    if (!saleStockStats?.length) return [];
    return saleStockStats?.map((saleStockStats) => {
      return {
        x: moment(saleStockStats.time).format('DD MMM HH:mm'),
        y: saleStockStats.quantity,
        fillColor: saleStockStats.quantity > 0 ? '#5ca3ff' : '#cc3300',
      };
    });
  }, [saleStockStats]);

  useEffect(() => {
    if (!transformData.length) return;

    refChart.current = new ApexCharts(
      document.getElementById(`chart`),
      dataCharts({
        title: '',
        data: transformData,
      })
    );
    refChart.current.render();

    return () => {
      refChart.current.destroy();
    };
  }, [saleStockStats, transformData]);

  const handleSelectProduct = (value) => {
    setBarCode(value);
  };

  return (
    <Row gutter={10} style={{ height: window.innerHeight - 95 }}>
      <Col span={5} style={{ flexGrow: 1, alignItems: 'stretch', alignContent: 'stretch' }}>
        <SCard className="h-full px-1">
          {isLoadingCategories ? (
            <div className="flex justify-center items-center h-full">
              <LoadingOutlined />
            </div>
          ) : (
            <Tree
              onSelect={(values) => setCategory(values?.[0])}
              fieldNames={{
                title: 'name',
                key: 'code',
                children: 'subs',
              }}
              treeData={subs}
              height={window.innerHeight - 120}
              rootStyle={{ height: '100%' }}
            />
          )}
        </SCard>
      </Col>
      <Col span={19} style={{ flexGrow: 1, alignItems: 'stretch', alignContent: 'stretch' }}>
        <SCard className="h-full">
          <div className="">
            <Row gutter={[10, 10]} className="mb-3">
              <Col span={7}>
                <ProductSelection
                  onSelectProduct={handleSelectProduct}
                  onClear={() => {
                    refChart.current.destroy();
                    setBarCode(null);
                  }}
                />
              </Col>
              <Col span={6}>
                <StoreSelection
                  stores={stores}
                  onChange={(value) => {
                    setStoreSelected(value);
                  }}
                />
              </Col>
              <Col span={4}>
                <Select
                  className="w-100"
                  onChange={(value) => {
                    setMertric(value);
                  }}
                  fieldNames={{
                    value: 'id',
                    label: 'name',
                  }}
                  options={stockStatMetrics}
                  allowClear
                  placeholder="Vui lòng chọn Metric"
                  optionFilterProp="children"
                ></Select>
              </Col>
              <Col span={2}>
                <Select
                  className="w-100"
                  value={numberBuckets}
                  onChange={(value) => {
                    setNumberBuckets(value);
                  }}
                  fieldNames={{
                    value: 'id',
                    label: 'id',
                  }}
                  options={NUMBER_BUCKETS_OPTIONS}
                  placeholder="Size"
                  optionFilterProp="children"
                ></Select>
              </Col>
              <Col span={5}>
                <RangePicker
                  format="DD/MM/YYYY"
                  value={rangeDate}
                  onChange={(values: any) => {
                    setRangeDate(values);
                  }}
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
          </div>
          <Row className="flex mt-3">
            <Col md={24}>
              {isLoadingSaleStockStats && (
                <div className="flex items-center justify-center h-full">
                  <LoadingOutlined />
                </div>
              )}
              {isEmptyData && <Empty className="mt-5 pt-5" description="Không có dữ liệu" />}
              <div id="chart"></div>
            </Col>
          </Row>
        </SCard>
      </Col>
    </Row>
  );
};

export default Stock;
