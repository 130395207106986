import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const updatePreOrderStock = async (params?: any) => {
  return await axiosClient.post<any>(`inventory/updatePreOrderStock`, {
    data: { ...params },
  });
};

export const useUpdatePreOrderStockMutation = () => {
  return useMutation({ mutationFn: (params: any) => updatePreOrderStock(params) });
};
