import { Popover } from 'antd';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { ContentProductPopOver } from '../ProductItem/ProductItem';
type Props = Partial<Product>;

const ProductTitle: React.FC<Props> = ({
  name,
  image,
  originPrice,
  sellPrice,
  stockAvailable,
  unit,
}) => {
  const popRef: any = useRef();

  return (
    <Popover
      trigger="hover"
      placement="right"
      content={
        <ContentProductPopOver {...{ image, name, originPrice, sellPrice, stockAvailable, unit }} />
      }
    >
      <Link to={''}>
        <span
          ref={popRef}
          onClick={() => console.log(popRef.current.getBoundingClientRect(), '333')}
        >
          {name}
        </span>
      </Link>
    </Popover>
  );
};

export default ProductTitle;
