import { Tag } from 'antd';
import React from 'react';
import { MembershipRankEntity } from '~/contants/order';

const CustomerRank = ({ rank, children }: { rank: string; children?: React.ReactNode }) => {
  if (!rank) return null;
  return (
    <Tag
      style={{
        marginRight: 0,
        fontSize: '12px',
        alignSelf: 'flex-start',
        color: MembershipRankEntity[rank]?.stroke,
      }}
      color={MembershipRankEntity[rank]?.bgColor}
    >
      <span>{MembershipRankEntity[rank]?.label}</span>
      {children}
    </Tag>
  );
};

export default CustomerRank;
