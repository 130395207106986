import axiosClient from '~/config/axiosClient';
import { useMutation } from 'react-query';

const createPreOrderStock = async (params?: any) => {
  return await axiosClient.post<any>(`inventory/createPreOrderStock`, {
    data: { ...params },
  });
};

export const useCreatePreOrderStockMutation = () => {
  return useMutation({ mutationFn: (params: any) => createPreOrderStock(params) });
};
