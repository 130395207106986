import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useOrderShareDetailQuery } from '~/queries/orders/useOrderDetailQuery';
import { Alert } from 'antd';
import { useSearchParams } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};

type DeliveryOrderBagsContextType = {
  deliveryOrderBags: {
    DRY: Array<any>;
    FROZEN: Array<any>;
    FRESH: Array<any>;
  };
  header?: any;
  isLoadingDeliveryOrderDetail: boolean;
  onSetDeliveryOrderBags?: (values: any) => void;
};

export const DeliveryOrderBagsContext = React.createContext<DeliveryOrderBagsContextType>({
  deliveryOrderBags: {
    DRY: [],
    FROZEN: [],
    FRESH: [],
  },
  isLoadingDeliveryOrderDetail: false,
});

const DeliveryOrderBagsContextProvider: React.FC<Props> = ({ children }): React.ReactElement => {
  const [searchParams] = useSearchParams();

  const orderCode: any = searchParams.get('orderCode');

  const { data: orderDetail, isLoading: isLoadingDeliveryOrderDetail } = useOrderShareDetailQuery(
    orderCode,
    {
      enabled: true,
    }
  );

  const { header } = orderDetail || {};
  const { bags } = header || {};

  const [deliveryOrderBags, onSetDeliveryOrderBags] = useState({
    DRY: [],
    FROZEN: [],
    FRESH: [],
  });

  const transformBagsData: any = useMemo(() => {
    if (!bags) return { DRY: [], FROZEN: [], FRESH: [] };
    const dry = bags.filter((bag) => bag.type === 'DRY');
    const frozen = bags.filter((bag) => bag.type === 'FROZEN');
    const fresh = bags.filter((bag) => bag.type === 'FRESH');

    const bagsType = {
      DRY: dry,
      FROZEN: frozen,
      FRESH: fresh,
    };

    return bagsType;
  }, [bags]);

  useEffect(() => {
    onSetDeliveryOrderBags(transformBagsData || []);
  }, [bags, transformBagsData]);

  const handleDeliverySetOrderBags = useCallback(
    (values) => {
      onSetDeliveryOrderBags(values);
    },
    [onSetDeliveryOrderBags]
  );

  const valueContext: DeliveryOrderBagsContextType = {
    deliveryOrderBags,
    isLoadingDeliveryOrderDetail,
    header,
    onSetDeliveryOrderBags: handleDeliverySetOrderBags,
  };

  if (orderDetail?.error) {
    return <Alert className="m-auto mt-4 mx-3" type="error" message={orderDetail.error} />;
  }

  return (
    <DeliveryOrderBagsContext.Provider value={valueContext}>
      {children}
    </DeliveryOrderBagsContext.Provider>
  );
};

export default DeliveryOrderBagsContextProvider;

export const useDeliveryOrderBagsContext = () => useContext(DeliveryOrderBagsContext);
