import { useQuery } from 'react-query';
import axiosClient from '~/config/axiosClient';
import { StatusOfOrder } from '~/contants/order';
import { transformParams } from '~/queries/orders/useOrdersQuery';

export type OrderExportRequest = {
  status?: StatusOfOrder;
  sortType?: string;
  sortBy?: string;
  refundStatus?: any;
  keyword?: string;
  orderTimeRange?: any;
};

const exportOrderRefunds = async (filter?: OrderExportRequest) => {
  const transformFilter = transformParams(filter);

  const newParam = { filter: JSON.stringify(transformFilter) };

  return await axiosClient.get('order/exportOrderRefunds', { params: newParam });
};

export const useExportOrderRefund = (filter?: OrderExportRequest, config = { enabled: false }) => {
  return useQuery(['exportOrderRefunds', filter], () => exportOrderRefunds(filter), {
    ...config,
  });
};
