import { message } from 'antd';
import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { PATH_NAME_NEED_REDIRECT_AFTER_LOGIN } from '~/contants/commons';
import { AuthContext } from '~/contexts/AuthContext';
import SeedcomBrowser from '~/contexts/SeedcomBrowser';

declare var window: any;

const inNeeddRedirectRouter = (cachePathName) => {
  return PATH_NAME_NEED_REDIRECT_AFTER_LOGIN.every((pathname) => {
    return cachePathName.startsWith(pathname);
  });
};

const Authorize = () => {
  const [, setToken] = useLocalStorage('token', null);
  const [, setUserInfo] = useLocalStorage('userInfo', null);
  const [searchParams] = useSearchParams();
  const { setIsAuth, setUsers } = useContext(AuthContext);
  const navigate = useNavigate();

  const cachePathName: any = JSON.parse(localStorage.getItem('pathname') as string);

  useEffect(() => {
    try {
      const authInfo = JSON.parse(searchParams.get('session') as any);
      const userInfo = { ...authInfo };
      delete userInfo.zas;
      const zas = authInfo?.zas;

      setToken(zas);
      setUserInfo(userInfo);
      setUsers?.(userInfo);
      setIsAuth(true);
      setTimeout(() => {
        if (window?.SeedcomBrowser) {
          SeedcomBrowser?.loginApp({
            authInfo,
          });
          return;
        }
        if (cachePathName && inNeeddRedirectRouter(cachePathName)) {
          localStorage.removeItem('pathname');
          navigate(cachePathName);
        } else {
          navigate('/');
        }
      }, 100);
    } catch (err) {
      message.error('Lỗi: ' + JSON.stringify(err));
    }
  }, [searchParams]);

  return <div className="text-center mt-5 font-medium">Vui lòng đợi ... </div>;
};

export default Authorize;
