import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { formatterNumber } from '~/components/commons/CurrencyFormat/CurrencyFormat';

export const BaseUtils = {
  getConfigWindow: () => {
    return (window as any)?.scConfig;
  },
  formatNumeric: (num = 0) => {
    return Intl.NumberFormat().format(num);
  },
  convertNumberToUnitString: (number: string | number) => {
    const numberString = String(number);
    if (numberString.length <= 3) {
      return numberString;
    }
    if (numberString.length <= 6) {
      const tPrefix = numberString.slice(0, numberString.length - 3);
      const tSuffix = numberString.slice(numberString.length - 3, numberString.length - 2);
      return tPrefix + (tSuffix && '.' + tSuffix) + 'K';
    }
    if (numberString.length <= 9) {
      const mPrefix = numberString.slice(0, numberString.length - 6);
      const mSuffix = numberString.slice(numberString.length - 6, numberString.length - 5);
      return mPrefix + (mSuffix && '.' + mSuffix + 'M');
    }
    if (numberString.length > 9) {
      const bPrefix = numberString.slice(0, numberString.length - 9);
      const bSuffix = numberString.slice(numberString.length - 9, numberString.length - 7);
      return bPrefix + (bSuffix && '.' + bSuffix + 'T');
    }
  },
  toHoursAndMinutes: (totalMinutes: number) => {
    const day = Math.floor(totalMinutes / 24 / 60);
    const hours = Math.floor((totalMinutes / 60) % 24);
    const minutes = totalMinutes % 60;

    return ` ${day > 0 ? ` ${day} ngày` : ''} ${hours > 0 ? ` ${hours} giờ` : ''} ${
      minutes > 0 ? ` ${minutes} phút` : ''
    }`;
  },
  convertJsonToString: (object: any) => {
    if (typeof object !== 'object') {
      return {};
    }
    let paramsString = '';
    let index = 0;
    Object.keys(object)?.map((key: any) => {
      if (object[key]) {
        let prefix = index === 0 ? '?' : '&';
        index++;
        paramsString += `${prefix}${key}=${object[key]}`;
      }
    });

    return String(paramsString);
  },

  timeSince: (date: any) => {
    var seconds = Math.floor(((new Date() as any) - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + ' years';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + ' months';
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + ' days';
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' hours';
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' minutes';
    }
    return Math.floor(seconds) + ' seconds';
  },
  initConfigDate: () => {
    moment.updateLocale('en', {
      relativeTime: {
        future: 'cách đây %s',
        past: '%s trước',
        s: 'vài giây',
        ss: '%d giây',
        m: '1 phút',
        mm: '%d phút',
        h: '1 giờ',
        hh: '%d giờ',
        d: '1 ngày',
        dd: '%d ngày',
        M: '1 tháng',
        MM: '%d tháng',
        y: '1 năm',
        yy: '%d năm',
      },
    });
  },
  randomArray: (array: Array<any>) => {
    const randomElement = array[Math.floor(Math.random() * array.length)];
    return randomElement;
  },
  formatNumber: (num?: number) => formatterNumber(num || 0)?.replace('₫', '') || 0,
  capitalizeFirstLetter: (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  },

  sortAlphaNumeric: (a: string | number, b: string | number) => {
    // convert to strings and force lowercase
    a = typeof a === 'string' ? a.toLowerCase() : a.toString();
    b = typeof b === 'string' ? b.toLowerCase() : b.toString();

    return a.localeCompare(b);
  },

  cleanArray: (array: Array<string | number>) => {
    return array.filter(function (el: any) {
      return el != null && el !== '';
    });
  },
  setDefaultTimeZone: () => {
    return momentTimezone.tz.setDefault('Asia/Ho_Chi_Minh');
  },
  millisToMinutes: (millis) => {
    const minutes = Math.floor(millis / 60000);
    return minutes;
  },
  removeAccents(str) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D');
  },
};
