import { ArrowLeftOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Affix, Avatar, Badge, Button, message, Space } from 'antd';

import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { SCard } from '~/components/commons';
import Coupon from '~/components/shared/Coupon';
import { useOrderDeliveryMutation } from '~/queries/orders/useOrderDeliveryMutation';
import { useCouponDetailContext } from '~/modules/operate-order/pages/CouponDetail/contexts/CouponDetailContext';
import { useCouponDrawerContext } from '~/modules/operate-order/pages/CouponDetail/contexts/CouponDrawerContext';
import { useProductCouponDetailContext } from '~/modules/operate-order/pages/CouponDetail/contexts/ProductCouponDetailContext';
import { SERVER_PREFIX } from '~/contants/commons';

const CouponEditOrder: React.FC = () => {
  const navigate = useNavigate();
  const {
    isLoading,
    data: deliveryResponse,
    mutate: updateDelivery,
    isSuccess,
  } = useOrderDeliveryMutation();

  const { delivery, orderDetail } = useCouponDetailContext();

  const { setShowProductForm } = useCouponDrawerContext();

  const {
    increaseQuantity,
    decreaseQuantity,
    onChangeQuantity,
    onRemoveProduct,
    productItems: orderProducts,
  } = useProductCouponDetailContext();

 

  const handleUpdateDelivery = () => {
    updateDelivery({
      data: { ...delivery, productItems: [...orderProducts] },
    });
  };

  useEffect(() => {
    if (!isSuccess) return;
    if (deliveryResponse?.error) {
      message.error(SERVER_PREFIX + deliveryResponse?.error);
    } else {
      message.success('Lưu thông tin thành công');
      navigate(-1);
    }
  }, [deliveryResponse, isSuccess]);

  return (
    <>
      <Affix offsetTop={70}>
        <SCard className="flex bg-white mb-2 justify-between" rounded>
          <Button onClick={() => navigate(-1)} type={'text'} icon={<ArrowLeftOutlined />}>
            Mã đơn hàng: #{orderDetail?.header?.code}
          </Button>
          <Space>
            <Button loading={isLoading} type="primary" onClick={handleUpdateDelivery}>
              Lưu
            </Button>
          </Space>
        </SCard>
      </Affix>
      <SCard rounded>
        <Coupon
          delivery={delivery}
          onDecreaseQuantity={decreaseQuantity}
          onIncreaseQuantity={increaseQuantity}
          onChangeQuantity={onChangeQuantity}
          onRemoveProduct={onRemoveProduct}
          onShowProductForm={() => setShowProductForm?.(true)}
          orderDetail={orderDetail}
          orderProducts={orderProducts}
        />
      </SCard>
      <Affix style={{ position: 'fixed', zIndex: 99999, bottom: 15, right: 15 }}>
        <Button type="link">
          <Badge count={orderProducts?.length || 0}>
            <Avatar
              style={{ backgroundColor: 'transparent' }}
              shape="circle"
              icon={
                <ShoppingCartOutlined style={{ fontSize: '28px', color: 'var(--primary-color)' }} />
              }
            />
          </Badge>
        </Button>
      </Affix>
    </>
  );
};

export default CouponEditOrder;
