import { Button } from 'antd';

import { HaravanIcon, LogoIcon } from '~/components/Icons';

import './Login.scss';

import { useLogin } from '../../hooks/useLogin';
import { useLocation } from 'react-router-dom';
import { useSetRedirectAfterLogin } from '~/hooks/useSetRedirectAfterLogin';
import { useEffect } from 'react';

const Login: React.FC<void> = () => {
  const location = useLocation();

  const { mutate: onLogin, isLoading: loadingLogin } = useLogin();
  const { setRedirectAfterLogin } = useSetRedirectAfterLogin();

  const { from }: any = location?.state || {};
  const { pathname } = from || {};

  useEffect(() => {
    setRedirectAfterLogin(pathname);
  }, [pathname]);

  return (
    <div className="form-login">
      <div className="form-content">
        <LogoIcon />
        <Button
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          icon={<HaravanIcon />}
          onClick={onLogin}
          loading={loadingLogin}
          type="primary"
        >
          Login with Haravan
        </Button>
      </div>
    </div>
  );
};

export default Login;
