import { useCallback, useEffect, useState } from 'react';

export const usePaging = ({ sizer }: { sizer: number }) => {
  const [values, setValues] = useState({
    sizer: sizer || 10,
    currentPage: 1,
  });

  const handleChangePagination = useCallback(({ page, size }: { page: number; size: number }) => {
    setValues((currentValue) => ({
      ...currentValue,
      currentPage: page,
      sizer: size,
    }));
  }, []);

  useEffect(() => {
    setPage(1);
  }, [values.sizer]);

  const setPage = useCallback((currentPage = 1) => {
    setValues((currentValue) => ({
      ...currentValue,
      currentPage,
    }));
  }, []);

  const actions = {
    onChangePagination: handleChangePagination,
    setPage,
  };

  const states = {
    ...values,
  };

  return {
    actions,
    states,
  };
};
