import { Select } from 'antd';
import React from 'react';
import { useGlobalContext } from '~/contexts/GlobalContext';
import { BaseUtils } from '~/ultils/base-utils';

const { Option } = Select;

const StoreSelection = ({ stores: storesProps, onChange, ...rests }: any) => {
  const { stores: storesGlobal }: any = useGlobalContext();
  const stores = storesProps || storesGlobal;

  return (
    <Select
      showSearch
      className="w-100"
      onChange={onChange}
      allowClear
      placeholder="Vui lòng chọn kho"
      optionFilterProp="children"
      filterOption={(input, option) => {
        const selected = stores.filter((sub: any) => sub.id === (option?.value as any))?.[0];
        const name = BaseUtils.removeAccents(selected?.name);
        const id = BaseUtils.removeAccents(selected?.id);
        const inputRemoveAccents = BaseUtils.removeAccents(input);

        return (
          (name ?? ('' as any)).toLowerCase().includes(inputRemoveAccents.toLowerCase()) ||
          (id ?? ('' as any)).toLowerCase().includes(inputRemoveAccents.toLowerCase())
        );
      }}
      {...rests}
    >
      {stores?.map((optionValue: any, index: number) => (
        <Option key={index} value={optionValue.id}>
          <div className="flex flex-col">
            <span className="font-medium">
              {optionValue.name} <span className="text-xs text-blue-500">- {optionValue.id}</span>
            </span>
            <span className="mt-1 text-gray-500 text-xs">{optionValue?.address}</span>
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default StoreSelection;
