import React, { memo, useMemo, useState } from 'react';
import { Pagination, PaginationProps, Select } from 'antd';

import './PaginationCustom.scss';
const { Option } = Select;

interface IPagination {
  total?: number;
  size?: number;
  current?: number;
  hiddenPerPage?: boolean;
  onChange?: ({
    page,
    size,
    hiddenPerPage,
  }: {
    page: number;
    size: number;
    hiddenPerPage?: boolean;
  }) => void;
}

const showTotal: PaginationProps['showTotal'] = (total) => `Tổng ${total}`;

const PaginationCustom: React.FC<IPagination> = ({
  total = 0,
  size = 10,
  current = 1,
  hiddenPerPage = false,
  onChange,
}): React.ReactElement => {
  const [page, setPage] = useState(1);
  return (
    <div className="pagination-custom flex flex-wrap gap-3">
      {hiddenPerPage && <div></div>}
      {!hiddenPerPage && (
        <Select
          value={size}
          style={{ width: 100 }}
          onChange={(size) => {
            onChange?.({ page, size });
          }}
        >
          <Option value={10}>10/trang</Option>
          <Option value={20}>20/trang</Option>
          <Option value={50}>50/trang</Option>
          <Option value={100}>100/trang</Option>
        </Select>
      )}
      <Pagination
        size="small"
        total={total || 0}
        pageSize={size}
        onChange={(page) => {
          setPage(page);
          onChange?.({ size, page });
        }}
        showTotal={showTotal}
        showSizeChanger={false}
        current={current}
      />
    </div>
  );
};

export default memo(PaginationCustom);
