import axiosClient from '~/config/axiosClient';
import { useQuery } from 'react-query';

const getMyProfile = async (params?: any) => {
  return await axiosClient.get<OrderListTypes>(`employee/getMyProfile`, {
    ...params,
  });
};

export const useGetMyProfileQuery = (params?: any, config = {}) => {
  return useQuery(['getMyProfile', params], () => getMyProfile({ params }), { ...config });
};
