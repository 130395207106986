import { useQuery } from 'react-query';
import axiosClient from '~/config/axiosClient';

export type ProductsStoreRequest = {
  storeCode?: string;
  keyword?: string;
};

const getProductsByStore = async ({ storeCode, keyword }: ProductsStoreRequest) => {
  if (!storeCode) return;
  const params = {
    storeCode,
    keyword,
  };
  return await axiosClient.get('product/searchStoreProducts', {
    params,
  });
};

export const useGetProductsByStore = (
  productStoreRequest: ProductsStoreRequest,
  config = { enabled: true }
) => {
  return useQuery(
    ['searchStoreProducts', productStoreRequest],
    () => getProductsByStore(productStoreRequest),
    {
      ...config,
    }
  );
};
