import {
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
  RedoOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Card, Image, Input, Popconfirm, Space, Tooltip, message } from 'antd';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import IPagination from '~/components/antd/IPagination';
import TableAntCustom from '~/components/antd/ITable/TableAntCustom';
import { SCard } from '~/components/commons';
import { SERVER_PREFIX, fallbackImage } from '~/contants/commons';
import { usePaging } from '~/hooks/usePaging';
import { useDeletePreOrderStockMutation } from '~/queries/inventory/useDeletePreOrderStockMutation';
import { useResetPreOrderStockMutation } from '~/queries/inventory/useResetPreOrderStockMutation';
import { useSearchPreOrderStocks } from '~/queries/inventory/useSearchPreOrderStocks';
import { BaseUtils } from '~/ultils/base-utils';

type ProductRecord = {
  barcode: string;
  quantityInit: number;
  productName: number;
  totalNumberOrders: number;
  totalQuantityOrdered: number;
  unitName: string;
  storeDistributions: Array<any>;
};

const columns = ({ onRemove, onReset, isLoadingResetPreOrderStock, isUpdatePreOrderStock }) => [
  {
    title: '#',
    dataIndex: 'productImage',
    className: 'text-center',
    key: 'productImage',
    render: (image: string) => (
      <Image
        wrapperStyle={{ maxWidth: '50px' }}
        fallback={fallbackImage}
        className="mt-3"
        preview={false}
        src={image || ''}
      />
    ),
  },
  {
    title: 'Tên SP',
    dataIndex: 'productName',
    key: 'productName',
  },
  {
    title: 'Barcode',
    dataIndex: 'barcode',
    key: 'barcode',
  },
  {
    title: 'SL khởi tạo',
    dataIndex: 'quantityInit',
    key: 'quantityInit',
    render: (quantityInit: any, record: ProductRecord) => {
      return (
        <span>
          {BaseUtils.formatNumber(Number(quantityInit))}{' '}
          <span className="text-gray-500">{record?.unitName?.toLowerCase()}</span>
        </span>
      );
    },
  },
  {
    title: 'Thống kê đặt hàng',
    dataIndex: 'totalNumberOrders',
    key: 'totalNumberOrders',
    render: (totalNumberOrders: number, record: ProductRecord) => {
      return (
        <Space direction="vertical" size="small">
          <span className="">
            {totalNumberOrders || 0} <span className="font-medium text-gray-500">đơn</span>
          </span>
          <span className="">
            {record?.totalQuantityOrdered || 0}{' '}
            {record?.unitName && (
              <span className="font-medium text-gray-500">{record?.unitName?.toLowerCase()}</span>
            )}
          </span>
        </Space>
      );
    },
  },
  {
    title: 'SL còn lại',
    dataIndex: 'quantityInit',
    key: 'quantityInit',
    render: (quantityInit: any, record: ProductRecord) => {
      const amountRemaining = Number(quantityInit) - Number(record?.totalQuantityOrdered);
      return (
        <span>
          <span className="mr-1">{amountRemaining}</span>
          <span className="font-medium text-gray-500">{record?.unitName?.toLowerCase()}</span>
        </span>
      );
    },
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdTime',
    key: 'createdTime',
    render: (createdTime: string) => {
      return moment(createdTime).format('DD/MM/YYYY');
    },
  },
  {
    title: '',
    className: 'text-center',
    dataIndex: 'barcode',
    key: 'barcode',
    render: (barcode: string, record: ProductRecord) => {
      return (
        <Space direction="horizontal" size="small">
          <Link
            to={`/system-setting/warehouse-products/stock-available/${barcode}`}
            state={{ ...record }}
          >
            <Tooltip title="Sửa" placement="bottom">
              <Button icon={<EditOutlined />} type="text" size="small" shape="round"></Button>
            </Tooltip>
          </Link>
          <Popconfirm
            placement="topRight"
            title={'Bạn có chắn không?'}
            onConfirm={() => onReset(record?.barcode)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Đặt lại" placement="bottom">
              <Button
                loading={isLoadingResetPreOrderStock}
                icon={<RedoOutlined />}
                type="text"
                size="small"
                shape="round"
              ></Button>
            </Tooltip>
          </Popconfirm>
          <Popconfirm
            placement="topRight"
            title={'Bạn có muốn chắn không?'}
            onConfirm={() => onRemove(record?.barcode)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Xoá" placement="bottom">
              <Button
                loading={isUpdatePreOrderStock}
                icon={<DeleteOutlined />}
                type="text"
                size="small"
                shape="round"
              ></Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      );
    },
  },
];

const InventoryProductList = () => {
  const [keyword, setKeyWord] = useState('');

  const [] = useDebounce(
    () => {
      setPage(1);
      fetchPreOrderStocks();
    },
    500,
    [keyword]
  );

  const {
    actions: { setPage, onChangePagination },
    states: { sizer, currentPage },
  } = usePaging({ sizer: 10 });

  const {
    data,
    isLoading,
    refetch: fetchPreOrderStocks,
  } = useSearchPreOrderStocks(
    {
      pageIndex: currentPage,
      limit: sizer,
      keyword,
    },
    { enabled: true }
  );
  useEffect(() => {
    fetchPreOrderStocks();
  }, []);

  const {
    mutate: deletePreOrderStock,
    isLoading: isUpdatePreOrderStock,
    isSuccess,
    data: dataDeletePreOrderStock,
  } = useDeletePreOrderStockMutation();

  useEffect(() => {
    if (dataDeletePreOrderStock?.error) {
      message.error(SERVER_PREFIX + dataDeletePreOrderStock?.error);
    } else if (isSuccess) {
      fetchPreOrderStocks();
      message.success('Xoá sản phẩm thành công');
    }
  }, [isSuccess]);

  const handleRemove = useCallback((barcode: string) => {
    deletePreOrderStock(barcode);
  }, []);

  const {
    mutate: resetPreOrderStock,
    isSuccess: isSuccessResetPreOrderStock,
    isLoading: isLoadingResetPreOrderStock,
    data: dataResetPreOrderStock,
  } = useResetPreOrderStockMutation();

  useEffect(() => {
    if (dataResetPreOrderStock?.error) {
      message.error(SERVER_PREFIX + dataResetPreOrderStock?.error);
    } else if (isSuccessResetPreOrderStock) {
      message.success('Reset sản phẩm thành công');
    }
  }, [isSuccessResetPreOrderStock]);

  const handleReset = useCallback((barcode: string) => {
    resetPreOrderStock(barcode);
  }, []);

  const navigate = useNavigate();

  const handleCreateStockAvailableProduct = () => {
    navigate('/system-setting/warehouse-products/create-stock-available');
  };

  const products = data?.list || [];

  return (
    <>
      <SCard title={'Danh sách sản phẩm'} bordered={false} className="border-slate-50">
        <div className="flex items-center justify-end my-2 mb-4">
          <Input
            className="mr-3 rounded"
            placeholder="Tìm kiếm sản phẩm theo tên, mã sản phẩm"
            style={{ width: 400 }}
            size="middle"
            allowClear
            onChange={(e: any) => setKeyWord(e.target.value)}
            prefix={<SearchOutlined />}
          />
          <Button
            type="primary"
            onClick={handleCreateStockAvailableProduct}
            ghost
            icon={<PlusOutlined />}
          >
            Tạo Stock PreOrder
          </Button>
        </div>
        <TableAntCustom
          pagination={false}
          loading={isLoading}
          dataSource={products}
          columns={columns({
            onRemove: handleRemove,
            onReset: handleReset,
            isLoadingResetPreOrderStock,
            isUpdatePreOrderStock,
          })}
        />
        <IPagination
          hiddenPerPage
          size={sizer}
          total={data?.total}
          current={currentPage}
          onChange={onChangePagination}
        />
      </SCard>
    </>
  );
};

export default InventoryProductList;
