import { CloudDownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, PageHeader, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import IPagination from '~/components/antd/IPagination';
import TableAntCustom from '~/components/antd/ITable/TableAntCustom';
import { SCard } from '~/components/commons';
import CustomerLink from '~/components/shared/_molecules/CustomerLink';
import { SERVER_PREFIX } from '~/contants/commons';
import { usePaging } from '~/hooks/usePaging';
import { useExportSorryVouchers } from '~/queries/orders/refund/useExportSorryVouchers';
import { useOrdersQuery } from '~/queries/orders/useOrdersQuery';

const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'Mã đơn hàng',
    dataIndex: 'code',
    key: 'code',
    render: (code: string, record: any) => {
      return (
        <div className="flex flex-col">
          <Link target={'_blank'} to={`/order/order-detail/${code}`}>
            {code}
          </Link>
          <span className="text-gray-500">
            {moment(record?.orderTime).format('DD/MM/YYYY HH:mm')}
          </span>
        </div>
      );
    },
  },
  {
    title: 'Khách hàng',
    dataIndex: 'customer',
    key: 'customer',
    render: (customer: any) => {
      return (
        <>
          <div>
            <CustomerLink customer={customer} />
          </div>
          <span style={{ color: 'rgb(131 125 125)', fontSize: '12px' }} className="text-xs ">
            {customer.phone}
          </span>
        </>
      );
    },
  },
  {
    title: 'NV xử lý',
    dataIndex: 'assignee',
    key: 'assignee',
    render: (assignee: any) => (
      <>
        <span>{assignee?.name || '--'}</span>
        <br />
        <span style={{ color: 'rgb(131 125 125)' }} className="text-xs ">
          {assignee?.phone}
        </span>
      </>
    ),
  },
  {
    title: 'SL Sorry Voucher',
    dataIndex: 'sorryVoucher',
    key: 'sorryVoucher',
    render: (sorryVoucher) => {
      return <span>{sorryVoucher?.codes?.length}</span>;
    },
  },
  {
    title: 'Ngày gửi Sorry Voucher',
    dataIndex: 'sorryVoucher',
    key: 'sorryVoucher',
    render: (sorryVoucher) => {
      return (
        <div className="flex flex-col">
          <span className="text-gray-500">
            {moment(sorryVoucher?.createdDate).format('DD/MM/YYYY HH:mm')}
          </span>
        </div>
      );
    },
  },
  {
    title: 'Lý do',
    dataIndex: 'sorryVoucher',
    key: 'sorryVoucher',
    render: (sorryVoucher) => {
      return <span>{sorryVoucher?.reason}</span>;
    },
  },
];

const ListSorryVoucher = () => {
  const [keyword, setKeyword] = useState('');
  const [timeRange, setTimeRange] = useState('');

  const {
    actions: { onChangePagination },
    states: { sizer, currentPage },
  } = usePaging({ sizer: 10 });

  const filters = {
    pageIndex: currentPage,
    limit: sizer,
    keyword,
    existSorryVoucher: true,
    orderTimeRange: timeRange,
  };

  const {
    isFetching: loadingExportOrder,
    data: exportOrderRes,
    refetch: exportOrder,
    isSuccess: isExportOrderSuccess,
  } = useExportSorryVouchers(filters);

  useEffect(() => {
    if (!isExportOrderSuccess) return;
    if (exportOrderRes?.error) {
      message.error(SERVER_PREFIX + exportOrderRes?.error);
    } else {
      window.open(exportOrderRes);
      message.success('Xuất file excel thành công');
    }
  }, [exportOrderRes, isExportOrderSuccess]);

  const { data: response, isLoading: loadingOrder } = useOrdersQuery(filters);

  const dataOrder = response?.list || [];
  return (
    <>
      <SCard className="p-5" rounded>
        <div className="mb-4 flex justify-between gap-4">
          <div>
            <Input
              allowClear
              onChange={(e: any) => {
                setKeyword(e.target.value);
              }}
              style={{ width: 400 }}
              suffix={<SearchOutlined />}
              className="mr-3"
              placeholder="Tìm theo mã đơn hàng, tên khách hàng, SDT"
            />
            <RangePicker
              format="DD-MM-YYYY"
              placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
              onChange={(dates: any) => {
                if (!dates) {
                  return setTimeRange('');
                }
                const startDateTransform = moment(
                  moment(Number(moment(dates[0]).valueOf())).startOf('day')
                ).valueOf();
                const endDateTransform = moment(
                  moment(Number(moment(dates[1]).valueOf())).endOf('day')
                ).valueOf();
                setTimeRange(startDateTransform + '-' + endDateTransform);
              }}
            />
          </div>
          <Button
            icon={<CloudDownloadOutlined />}
            loading={loadingExportOrder}
            onClick={exportOrder}
          >
            Xuất file excel
          </Button>
        </div>
        <TableAntCustom
          iKey="code"
          dataSource={dataOrder || []}
          columns={columns}
          loading={loadingOrder}
          pagination={false}
        ></TableAntCustom>
        <IPagination
          hiddenPerPage
          size={sizer}
          total={response?.total || 0}
          current={currentPage}
          onChange={onChangePagination}
        />
      </SCard>
    </>
  );
};

export default ListSorryVoucher;
