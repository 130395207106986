import { message } from 'antd';
import { SERVER_PREFIX } from '~/contants/commons';
import { useLoginQuery } from '~/queries/auth/useLoginQuery';

export const useLogin = () => {
  const onSuccess = (values: any) => {
    if (values && values.error) {
      message.error(SERVER_PREFIX + values.error);
      return;
    }
    window.location.href = values;
  };

  const query = useLoginQuery({
    onSuccess,
  });

  return {
    ...query,
  };
};
