import { CheckOutlined, StarOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';

import { LeftOutlined, RightOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Button, Dropdown, Form, Layout, Popover, Select, Space, message } from 'antd';

import { Content } from 'antd/lib/layout/layout';
import { Notification, NotificationMute } from '~/components/Icons';

import { useNavigate } from 'react-router-dom';
import { createBreakpoint, useLocalStorage } from 'react-use';
import BtnCreateOrder from '~/components/shared/_molecules/BtnCreateOrder';
import { SERVER_PREFIX } from '~/contants/commons';
import { useAuthContext } from '~/contexts/AuthContext';
import { useGlobalContext } from '~/contexts/GlobalContext';
import useSidebar from '~/hooks/useSidebar';
import { useLogout } from '~/modules/auth/hooks/useLogout';
import { useUpdateOrderAssignSetting } from '~/queries/employee/useUpdateOrderAssignSetting';
import { useGetOrderRatingTicketStatusCounters } from '~/queries/orders/useGetOrderRatingTicketStatusCounters';
import { userMenu } from './contanst';

const { Header } = Layout;

const useBreakpoint = createBreakpoint();

const AssignForm = ({ onSubmit, orderAssignSetting }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      ...orderAssignSetting,
    });
  }, [form, orderAssignSetting]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item name="status" label="Trạng thái" style={{ width: '350px' }}>
        <Select>
          <Select.Option value="ENABLE">Sẵn sàng</Select.Option>
          <Select.Option value="DISABLE">Chưa sẵn sàng</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="types" label="Đơn xử lý" style={{ width: '350px' }}>
        <Select mode="multiple">
          <Select.Option value="NEW_ORDER">Đơn mới</Select.Option>
          <Select.Option value="RATING_ORDER">Đơn rating</Select.Option>
        </Select>
      </Form.Item>
      <div className="flex justify-end">
        <Button htmlType="submit" type="primary">
          Lưu
        </Button>
      </div>
    </Form>
  );
};

const IHeader = ({ title }: { title: any }): any => {
  const queryClient = useQueryClient();
  const navigator = useNavigate();
  const { onLogout } = useLogout();
  const { toggle, isOpenSidebar } = useSidebar();
  const [operationType , setOperationTypeLocalStorerage] = useLocalStorage("operationType", null);

  const { users: userInfo, profileData, setOperationType } = useAuthContext();
  const { onSetSettings, settings, orderOperationTypes } = useGlobalContext() as any;

  useEffect(() => {
    setOperationType?.(operationType as any)
  }, [operationType, setOperationType])

  const { status } = profileData?.data?.orderAssignSetting || {};
  const { role } = profileData?.data || {};

  const breakpoint = useBreakpoint();

  const isTablet = breakpoint === 'tablet';

  const { data: ratingTicketStatusCounters } = useGetOrderRatingTicketStatusCounters(
    {},
    'counterSidebar'
  );

  const counterTicketFilters = useMemo(() => {
    if (ratingTicketStatusCounters?.error) return [];
    return (
      ratingTicketStatusCounters?.filter(
        ({ channel, status }) =>
          ['KINGFOOD', 'ONELIFE'].includes(channel) && status === 'INITIALIZED'
      ) || []
    );
  }, [ratingTicketStatusCounters]);

  const { mutate: updateOrderAssignSetting, isSuccess, data } = useUpdateOrderAssignSetting();

  useEffect(() => {
    if (data?.error) {
      message.error(SERVER_PREFIX + data?.error);
    } else if (isSuccess) {
      message.success('Thành công');
      queryClient.invalidateQueries({ queryKey: ['getMyProfile'] });
    }
  }, [isSuccess]);

  const handleChangeNotice = useCallback(
    (value) => {
      onSetSettings({ ...settings, enableNotice: value });
    },
    [onSetSettings, settings]
  );

  const handleUpdateOrderAssignSetting = useCallback(
    (values) => {
      updateOrderAssignSetting(values);
    },
    [updateOrderAssignSetting]
  );

  const handleClickTicketStatus = useCallback((channel) => {
    navigator(`/order/order-rating?channel=${channel}`);
  }, []);

  const handleSelectOperationType = useCallback((value) => {
    setOperationTypeLocalStorerage(value)
    setOperationType?.(value);
  }, [setOperationType, setOperationTypeLocalStorerage]);

  return (
    <Header
      style={{
        backgroundColor: 'var(--bg-white)',
        padding: '0px 19px 0px 0px',
        borderBottom: '1px solid #dfdfdf',
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <Content className="flex items-center justify-between h-full">
        <div className="flex items-center">
          <Button
            onClick={() => {
              toggle(!isOpenSidebar);
            }}
            className="text-primary"
            type="link"
            icon={!isOpenSidebar ? <LeftOutlined default /> : <RightOutlined default />}
          ></Button>
          {title && !isTablet && <h3 className="mb-0">{title}</h3>}
        </div>

        <Space>
          <Select onChange={handleSelectOperationType} style={{ width: '150px' }} value={operationType || null} className="rounded-full">
            <Select.Option value={null}>Tất cả</Select.Option>
              {orderOperationTypes?.map(({ name, id }) => (
                <Select.Option value={id}>{name}</Select.Option>
              ))}
          </Select>
          <div className="flex gap-x-5 mr-2">
            {counterTicketFilters?.map(({ channel, count }) => {
              return (
                <Badge count={count}>
                  <Button
                    icon={<StarOutlined />}
                    onClick={() => handleClickTicketStatus(channel)}
                    size="small"
                    shape="round"
                  >
                    {channel}
                  </Button>
                </Badge>
              );
            })}
            
          </div>
          {role === 'CS' && (
            <>
              <Popover
                content={
                  <div className="flex flex-col">
                    <AssignForm
                      onSubmit={handleUpdateOrderAssignSetting}
                      orderAssignSetting={profileData?.data?.orderAssignSetting}
                    />
                  </div>
                }
                title="Gắn đơn"
                placement="bottomLeft"
                trigger="click"
              >
                <Button
                  size="small"
                  loading={profileData.loading}
                  type={status === 'ENABLE' ? 'primary' : 'dashed'}
                  danger={status === 'DISABLE'}
                  icon={<CheckOutlined />}
                >
                  {status === 'ENABLE' ? 'Sẵn sàng' : 'Chưa sẵn sàng'}
                </Button>
              </Popover>
            </>
          )}
          <BtnCreateOrder />
          <div
            className="flex items-center"
            style={{ cursor: 'pointer' }}
            onClick={() => handleChangeNotice(!settings.enableNotice)}
          >
            {settings.enableNotice ? <Notification /> : <NotificationMute color="red" />}
          </div>
          <Dropdown overlay={userMenu(onLogout, userInfo)}>
            <Button type="link" icon={<UserOutlined className="text-primary" />}></Button>
          </Dropdown>
        </Space>
      </Content>
    </Header>
  );
};

export default IHeader;
