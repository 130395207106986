import ListCustomer from './components/ListCustomer';

const CustomerWrapper = () => {
  return (
    <>
      <ListCustomer />
    </>
  );
};

export default CustomerWrapper;
