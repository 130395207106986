import { useQuery } from 'react-query';
import axiosClient from '~/config/axiosClient';

const searchEmployees = async (filters) => {
  const newParam = { filter: JSON.stringify(filters) };
  return await axiosClient.get('employee/searchEmployees', {
    params: { ...newParam },
  });
};

export const useSearchEmployees = (
  filters,
  config = { enabled: true, cacheTime: 0, staleTime: 0 }
) => {
  return useQuery(['searchEmployees'], () => searchEmployees(filters), {
    ...config,
  });
};
