import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
const SError = () => {
  return (
    <div className="rounded shadow-lg px-5 py-2 bg-white text-center">
      <WarningOutlined style={{ fontSize: '20px', color: 'red' }} />
      <h4>Đã xảy ra lỗi không xác định</h4>
    </div>
  );
};

export default SError;
