import { Button, DatePicker, Image, Input, Popover, Select, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import IPagination from '~/components/antd/IPagination';
import TableAntCustom from '~/components/antd/ITable/TableAntCustom';
import { SCard, StoreSelection } from '~/components/commons';
import ProductTitle from '~/components/shared/_molecules/ProductTitle';
import ProductSelection from '~/components/shared/ProductSelection';
import { fallbackImage } from '~/contants/commons';
import { useGlobalContext } from '~/contexts/GlobalContext';
import { usePaging } from '~/hooks/usePaging';
import { useSearchStockLogs } from '~/queries/tracking/useSearchStockLogs';
import { BaseUtils } from '~/ultils/base-utils';
import { findConfigById } from '~/ultils/config-utils';
import { useDebounce } from 'react-use';
import OrderResersesModal from './OrderResersesModal';

const columns = ({ onOpenOrderReserveQuantity }) => [
  {
    title: '#',
    dataIndex: 'productImage',
    className: 'text-center',
    key: 'productImage',
    width: 80,
    render: (image: string) => (
      <Image
        wrapperStyle={{ maxWidth: '50px' }}
        fallback={fallbackImage}
        className="mt-3"
        preview={false}
        src={image || ''}
      />
    ),
  },
  {
    title: 'Sản phẩm',
    dataIndex: 'barcode',
    key: 'barcode',
    render: (barcode: string, record: any) => {
      const storeName: any = findConfigById(BaseUtils.getConfigWindow()?.stores, record?.storeCode);
      return (
        <div className="flex flex-col gap-1">
          <span className="text-xs">
            <ProductTitle
              {...{ image: record.productImage, name: record.productName }}
            ></ProductTitle>
          </span>
          <div className="flex gap-2 items-center">
            <span>{barcode}</span>
            <Tag color="blue">{storeName}</Tag>
          </div>
        </div>
      );
    },
  },
  {
    title: 'Event',
    dataIndex: 'event',
    key: 'event',
    width: 200,
    render: (event: string, record: any) => {
      const eventName: any = findConfigById(BaseUtils.getConfigWindow()?.syncStockEvents, event);

      return <span>{eventName}</span>;
    },
  },
  {
    title: 'Variant type',
    dataIndex: 'type',
    width: 150,
    key: 'type',
  },
  {
    title: 'Sale Stock',
    dataIndex: 'quantity',
    key: 'quantity',
    className: 'text-center',
    width: 140,
    render: (quantity, { unit = {} }: any) => {
      return (
        <div>
          <span>{quantity}</span>&nbsp;
          <span className="text-blue-500">{unit?.name}</span>
        </div>
      );
    },
  },
  {
    title: 'Debug stock',
    dataIndex: 'kdbAvailableQuantity',
    key: 'kdbAvailableQuantity',
    width: 300,
    render: (
      kdbAvailableQuantity,
      { orderReserveQuantity, predictFutureQuantity, excludePromotionQuantity, orderReserses }
    ) => {
      const hasReserveQuantity = Object.keys(orderReserses || {})?.length;

      return (
        <div className="flex flex-col justify-start text-gray-500">
          <span className="whitespace-no-wrap">KDB Stock: {kdbAvailableQuantity}</span>
          <span className="whitespace-no-wrap">Future Stock: {predictFutureQuantity}</span>
          <div>
            <Button
              className="pl-0"
              type={Object.keys(orderReserses || {})?.length > 0 ? 'link' : 'text'}
              onClick={() => onOpenOrderReserveQuantity(orderReserses)}
              disabled={!Object.keys(orderReserses || {})?.length}
            >
              <span
                className={` whitespace-no-wrap text-gray-500  ${
                  hasReserveQuantity && 'text-blue-500 underline'
                }`}
              >
                Order Reserve: {orderReserveQuantity}
              </span>
            </Button>
          </div>
          <span className="whitespace-no-wrap">Exclude Promotion: {excludePromotionQuantity}</span>
        </div>
      );
    },
  },
  {
    title: 'Thời gian',
    dataIndex: 'createdTime',
    key: 'createdTime',
    className: 'text-center',
    width: 185,
    render: (createdTime) => {
      return <span>{moment(createdTime).format('DD/MM/YYYY HH:mm:ss')}</span>;
    },
  },
];

const { RangePicker } = DatePicker;

const TrackingStock = () => {
  const { stores, syncStockEvents }: any = useGlobalContext();
  const [isOpenOrderReserveQuantity, setIsOpenOrderReserveQuantity] = useState(false);
  const [orderReserses, setOrderReserses] = useState();

  const [keyword, setKeyWord] = useState('');
  const [event, setEvent] = useState();
  const firstTime = useRef(true);

  const [createdTimeRange, setCreatedTimeRange] = useState('');
  const [barcode, setBarCode] = useState('');
  const [storeCode, setStoreCode] = useState('');
  const {
    actions: { setPage, onChangePagination },
    states: { sizer, currentPage },
  } = usePaging({ sizer: 10 });

  const filters = {
    pageIndex: currentPage,
    limit: sizer,
    barcode,
    storeCode,
    createdTimeRange,
    keyword,
    event,
  };

  const {
    data: response,
    isLoading: loadingStock,
    isFetching: fetchingStock,
    refetch: fetchTrackingStocks,
  } = useSearchStockLogs({ ...filters });

  useEffect(() => {
    setPage(1);
    fetchTrackingStocks();
  }, [barcode, createdTimeRange, event, fetchTrackingStocks, setPage, storeCode, sizer]);

  useEffect(() => {
    fetchTrackingStocks();
  }, [currentPage]);

  useEffect(() => {
    setTimeout(() => {
      firstTime.current = false;
    }, 550);
  }, []);

  const [] = useDebounce(
    () => {
      if (firstTime.current) return;
      setPage(1);
      fetchTrackingStocks();
    },
    500,
    [keyword]
  );

  const stocks = response?.list || [];
  return (
    <>
      <SCard className="p-5" rounded>
        <div className="mb-4 flex gap-3 justify-end">
          <Input
            allowClear
            style={{ width: 400 }}
            suffix={<SearchOutlined />}
            onChange={(e: any) => setKeyWord(e.target.value)}
            placeholder="Tìm kiếm theo từ khoá"
          />
          <Select
            style={{ width: 300 }}
            onChange={(value) => {
              setEvent(value);
            }}
            fieldNames={{
              value: 'id',
              label: 'name',
            }}
            options={syncStockEvents}
            allowClear
            placeholder="Event"
            optionFilterProp="children"
          ></Select>

          <div style={{ width: 300 }}>
            <StoreSelection
              stores={stores}
              onChange={(value) => {
                setStoreCode(value);
              }}
              onClear={() => {
                setStoreCode('');
              }}
            />
          </div>
          <div style={{ width: 300 }}>
            <ProductSelection
              onSelectProduct={setBarCode}
              onClear={() => {
                setBarCode('');
              }}
            />
          </div>
          <RangePicker
            format="DD-MM-YYYY"
            placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
            onChange={(dates: any) => {
              if (!dates) {
                return setCreatedTimeRange('');
              }
              const startDateTransform = moment(
                moment(Number(moment(dates[0]).valueOf())).startOf('day')
              ).valueOf();
              const endDateTransform = moment(
                moment(Number(moment(dates[1]).valueOf())).endOf('day')
              ).valueOf();
              setCreatedTimeRange(startDateTransform + '-' + endDateTransform);
            }}
          />
        </div>
        <TableAntCustom
          iKey="code"
          dataSource={stocks || []}
          columns={columns({
            onOpenOrderReserveQuantity: (orderReserses) => {
              setIsOpenOrderReserveQuantity(true);
              setOrderReserses(orderReserses);
            },
          })}
          loading={loadingStock || fetchingStock}
          pagination={false}
        ></TableAntCustom>
        <IPagination
          hiddenPerPage
          size={sizer}
          total={response?.total || 0}
          current={currentPage}
          onChange={onChangePagination}
        />
      </SCard>
      <OrderResersesModal
        onCancel={() => setIsOpenOrderReserveQuantity(false)}
        isModalOpen={isOpenOrderReserveQuantity}
        orderReserses={orderReserses}
      />
    </>
  );
};

export default TrackingStock;
