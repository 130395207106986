import axiosClient from '~/config/axiosClient';
import { useQuery } from 'react-query';

const getCategoryTree = async () => {
  return await axiosClient.get<any>(`category/getCategoryTree`);
};

export const useGetCategoryTree = (config = {}) => {
  return useQuery(['getCategoryTree'], () => getCategoryTree(), {
    ...config,
  });
};
