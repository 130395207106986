import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useCouponDetailContext } from './CouponDetailContext';

// Quản lý trạng thái của danh sách sản phẩm trong phiếu giao hàng

type PropsContext = {
  productItems: any;
  increaseQuantity?: () => void;
  decreaseQuantity?: () => void;
  onChangeQuantity?: ({ barcode, amount }: { barcode: string; amount: number }) => void;
  onRemoveProduct?: (product: Product) => void;
  onAddProduct?: ({ product, amount }: { product: Product; amount: number }) => void;
};

const ProductCouponDetailContext = React.createContext<PropsContext>({
  productItems: [],
});

const ProductDetailCouponProvider = ({ children }: { children: React.ReactNode }) => {
  const { orderDetail } = useCouponDetailContext();

  const { delivery } = orderDetail || {};

  const [productItems, setProductItems] = useState<any>([]);
  const firstLoad = useRef(true);

  useEffect(() => {
    if (delivery?.productItems && firstLoad.current) {
      firstLoad.current = false;
      setProductItems(delivery?.productItems || []);
    }
  }, [delivery]);

  const increaseQuantity = useCallback(
    (barcode: string) => {
      const productIndex = productItems.findIndex((pr: any) => pr.barcode === barcode);
      if (productIndex !== -1) {
        productItems[productIndex].quantity += 1;
        setProductItems([...productItems]);
      }
    },
    [productItems]
  );

  const decreaseQuantity = useCallback(
    (barcode: string) => {
      const productIndex = productItems.findIndex((pr: any) => pr.barcode === barcode);
      if (productItems[productIndex].quantity <= 1) return;
      if (productIndex !== -1) {
        productItems[productIndex].quantity -= 1;
        setProductItems([...productItems]);
      }
    },
    [productItems]
  );

  const handleChangeQuantity = useCallback(
    ({ barcode, amount }: { barcode: string; amount: number }) => {
      const productIndex = productItems.findIndex((pr: any) => pr.barcode === barcode);
      if (productIndex !== -1) {
        productItems[productIndex].quantity = Number(amount) <= 0 ? 1 : Number(amount);
        setProductItems([...productItems]);
      }
    },
    [productItems]
  );

  const handleRemoveProduct = useCallback(
    (newProduct: any) => {
      const productsCopy = [...productItems];
      const curentIndexProduct: any = productsCopy?.findIndex(
        (product: Product) => String(product.barcode) === String(newProduct.barcode)
      );
      if (curentIndexProduct !== -1) {
        productsCopy?.splice(curentIndexProduct, 1);
        setProductItems([...productsCopy]);
      }
    },
    [productItems]
  );

  const handleAddNewProduct = useCallback(
    ({ amount, product: newProduct }: { amount: number; product: Product }) => {
      const curentIndexProduct: any = productItems.findIndex(
        (product: any) => product.barcode === newProduct.barcode
      );
      const curentProduct = productItems[curentIndexProduct];
      if (curentProduct) {
        const newQuantity = (curentProduct.quantity += amount);

        const combineProduct = {
          ...curentProduct,
          quantity: newQuantity,
        };
        productItems[curentIndexProduct] = { ...combineProduct };

        setProductItems([...productItems]);
      } else {
        setProductItems((currentProducts: any) => [
          ...currentProducts,
          {
            ...newProduct,
            attributes: {},
            barcode: newProduct?.barcode,
            discount: 0,
            name: newProduct?.name,
            image: newProduct?.image,
            originPrice: newProduct?.originPrice,
            quantity: amount,
            sellPrice: newProduct?.sellPrice,
            unitType: 'KG',
          },
        ]);
      }
    },
    [productItems]
  );

  const valueContext: any = useMemo(
    () => ({
      productItems,
      increaseQuantity,
      decreaseQuantity,
      onChangeQuantity: handleChangeQuantity,
      onAddProduct: handleAddNewProduct,
      onRemoveProduct: handleRemoveProduct,
    }),
    [productItems]
  );

  return (
    <ProductCouponDetailContext.Provider value={valueContext}>
      {children}
    </ProductCouponDetailContext.Provider>
  );
};

export default ProductDetailCouponProvider;

export const useProductCouponDetailContext = () => useContext(ProductCouponDetailContext);
