import axiosClient from '~/config/axiosClient';
import { useQuery } from 'react-query';
import moment from 'moment';
import { ErrorOfOrder, StatusOfOrder } from '~/contants/order';

const getOrders = async (params?: any) => {
  const transformFilter = transformParams(params);

  console.log(JSON.stringify(transformFilter));

  const newParam = { filter: JSON.stringify(transformFilter) };

  return await axiosClient.get<OrderListTypes>(`order/searchOrders`, {
    params: newParam,
  });
};

export const useOrdersQuery = (params?: any, config = { enabled: true }) => {
  if (params.status === StatusOfOrder.ERROR) {
    delete params.status;
    params = {
      ...params,
      cancelCode: [
        ErrorOfOrder.ERROR_STORE_OUT_OF_STOCK,
        ErrorOfOrder.ERROR_SHIPPING_NOT_FOUND_DRIVER,
        ErrorOfOrder.ERROR_SHIPPING_NOT_DELIVERY_CUSTOMER,
        ErrorOfOrder.ERROR_OTHER,
      ],
    };
  }
  return useQuery(['searchOrders', params], () => getOrders(params), {
    enabled: config.enabled,
    cacheTime: 0,
    staleTime: 0,
  });
};

export function transformParams(filter: any) {
  try {
    if (filter.orderTimeRange) {
      const orderTimeRangeSplit = filter.orderTimeRange.split('-');
      const startDateTransform = moment(
        moment(Number(orderTimeRangeSplit[0].trim())).startOf('day')
      ).valueOf();
      const endDateTransform = moment(
        moment(Number(orderTimeRangeSplit[1].trim())).endOf('day')
      ).valueOf();
      return {
        ...filter,
        orderTimeRange: startDateTransform.toString() + '-' + endDateTransform.toString(),
      };
    }
    return filter;
  } catch (err) {
    return filter;
  }
}
